<template>
  <div
    class="hero-slider__slide"
    :style="{
      backgroundImage:
        'url(' + heroData.fields.currencyHeroImage.fields.umbracoFile.src + ')',
    }"
  >
    <CurrencyForm
      :heroData="heroData"
      :currencySiteSettings="herosSiteSettings"
    />
    <div :class="heroData.fields.applyGradient ? 'hero__gradient' : ''"></div>
  </div>
</template>

<script>
import CurrencyForm from "@/components/InnerForms/Currency.vue";

export default {
  name: "HeroCurrency",
  props: ["heroData", "herosSiteSettings"],
  components: {
    CurrencyForm,
  },
};
</script>

<style lang="scss"></style>
