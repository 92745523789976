<template>
  <div class="content-timeline">
    <div class="content-timeline__header">
      <FlightStatusComponent />
    </div>
    <div class="content-timeline__main">
      <div class="content-timeline__timeline">
        <div class="content-timeline__body">
          <div
            class="content-timeline__timeline-track"
            :style="{ height: lastMarkerPosition + 'px' }"
          ></div>

          <div class="content-timeline__timeline-inner">
            <div class="content-timeline__timeline">
              <div
                class="timeline__section"
                v-for="(item, sectionIndex) in componentData.fields
                  .timelineSections"
                :key="sectionIndex"
                :id="'section' + sectionIndex"
              >
                <div class="timeline__section-header">
                  <div class="timeline__section-number">
                    <span>{{ sectionIndex + 1 }}</span>
                  </div>
                  <div class="timeline__section-title">
                    <h2>{{ item.fields.title }}</h2>
                  </div>
                </div>
                <div class="timeline__section-body">
                  <div class="timeline__section-body-content">
                    <template
                      v-for="(component, panelIndex) in item.fields
                        .timelinePanels"
                      :key="panelIndex"
                    >
                      <div
                        v-if="
                          renderStatus[sectionIndex] &&
                          renderStatus[sectionIndex][panelIndex]
                        "
                        class="timeline__section-marker"
                        ref="markers"
                      >
                        <span></span>
                      </div>
                      <component
                        v-if="
                          renderStatus[sectionIndex] &&
                          renderStatus[sectionIndex][panelIndex]
                        "
                        :is="component.system.contentType"
                        :componentData="component"
                        :selectedLanguage="selectedLanguage"
                        :pageInfo="info"
                      ></component>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-timeline__sidebar">
        <aside class="content-timeline__links">
          <ol class="content-timeline__links-list">
            <li
              class="content-timeline__links-item"
              v-for="(item, index) in componentData.fields.timelineSections"
              :key="index"
            >
              <a
                v-bind:href="'#section' + index"
                class="content-timeline__link"
              >
                {{ item.fields.title }}
              </a>
            </li>
          </ol>
        </aside>
      </div>
    </div>
  </div>
</template>

<script>
import { toRaw, reactive } from "vue";
import FlightStatusComponent from "@/components/FlightStatusComponent.vue";
import TimelinePanel from "@/components/TimelinePanels/TimelinePanelBasic";
import TimelineTabbed from "@/components/TimelinePanels/TimelineTabbedPanel";
import TimelineImageBlock from "@/components/TimelinePanels/TimelineImageBlock.vue";
import TimelineImage from "@/components/TimelinePanels/TimelineImage";
import TimelineListBlockPanel from "@/components/TimelinePanels/TimelineListBlockPanel";
import TimelineSecurityPanel from "@/components/TimelinePanels/TimelineSecurityPanel";
import TimelineParkingPanel from "@/components/TimelinePanels/TimelineParkingPanel.vue";
import TimelineCarHirePanel from "@/components/TimelinePanels/TimelineCarHirePanel.vue";
import TimelineInsurancePanel from "@/components/TimelinePanels/TimelineInsurancePanel.vue";
import TimelineTaxiPanel from "@/components/TimelinePanels/TimelineTaxiPanel.vue";
import axios from "axios";

export default {
  name: "TimelineComponent",
  props: ["componentData", "flightNumber", "flightDate"],
  components: {
    FlightStatusComponent,
    TimelinePanel,
    TimelineImageBlock,
    TimelineImage,
    TimelineListBlockPanel,
    TimelineSecurityPanel,
    TimelineTabbed,
    TimelineCarHirePanel,
    TimelineParkingPanel,
    TimelineTaxiPanel,
    TimelineInsurancePanel,
  },
  data() {
    return {
      markerElements: [],
      lastMarkerPosition: 0,
      flightData: null,
      renderStatus: reactive({}),
      serverTime: null,
    };
  },
  mounted() {
    this.fetchFlightData();
    this.getAllMarkerElements();
  },
  methods: {
    async fetchServerTime() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_REMOTEBASEURL}/api/TimelinePersonalisationApi/GetServerTime`
        );
        return new Date(response.data.serverTime);
      } catch (error) {
        console.error("Error retrieving server time:", error);
        throw error;
      }
    },
    getAllMarkerElements() {
      setTimeout(() => {
        const markerElements = document.querySelectorAll(
          ".timeline__section-marker"
        );

        if (markerElements.length > 0) {
          this.markerElements = [...markerElements];
          this.calculateLastMarkerPosition();
        }
      }, 400);
    },
    calculateLastMarkerPosition() {
      const timelineElement = document.querySelector(
        ".content-timeline__timeline"
      );
      const lastMarker = this.markerElements[this.markerElements.length - 1];

      if (timelineElement && lastMarker) {
        const timelineRect = timelineElement.getBoundingClientRect();
        const markerRect = lastMarker.getBoundingClientRect();

        const distanceFromTimelineTop = markerRect.top - timelineRect.top;

        this.lastMarkerPosition = distanceFromTimelineTop;
      } else {
        this.lastMarkerPosition = 0;
      }
    },
    async initializeRenderStatus() {
      for (let sectionIndex in this.componentData.fields.timelineSections) {
        const section =
          this.componentData.fields.timelineSections[sectionIndex];

        if (!this.renderStatus[sectionIndex]) {
          this.renderStatus[sectionIndex] = [];
        }
        for (let panelIndex in section.fields.timelinePanels) {
          const panel = section.fields.timelinePanels[panelIndex];
          const render = await this.renderComponent(panel);
          this.renderStatus[sectionIndex][panelIndex] = render;
        }
      }
    },
    async renderComponent(component) {
      if (!this.flightData) {
        return false; // Don't render until flightData is available
      }

      const rawComponent = toRaw(component);
      if (rawComponent.fields) {
        const flightRules = rawComponent.fields.flightRules || [];
        const userRules = rawComponent.fields.userRules || null; // Ensure userRules is either an object or null

        console.log("Flight rules for panel:", flightRules);
        console.log("User rules for panel:", userRules);

        const hasFlightRules = flightRules.length > 0;
        const hasUserRules =
          userRules && typeof userRules === "object" && userRules.id; // Check if userRules object exists and has an id

        console.log("Has flight rules:", hasFlightRules);
        console.log("Has user rules:", hasUserRules);

        if (!hasFlightRules && !hasUserRules) {
          console.log("No rules, panel will render.");
          return true; // Render if no rules are present
        }

        let flightRulesMatch = false; // Assume flight rules don't match unless evaluated
        let userRulesMatch = false; // Assume user rules don't match unless evaluated

        if (hasFlightRules) {
          console.log("Processing flight rules...");
          flightRulesMatch = await this.processFlightRules(
            flightRules,
            rawComponent.fields.matchAll
          );
          console.log("Flight rules evaluation result:", flightRulesMatch);
        }

        if (hasUserRules) {
          console.log("Processing user rule...");
          userRulesMatch = await this.processUserRules(userRules); // Process the single user rule object
          console.log("User rule evaluation result:", userRulesMatch);
        }

        console.log("Final render decision logic:");
        console.log("flightRulesMatch:", flightRulesMatch);
        console.log("userRulesMatch:", userRulesMatch);
        console.log("matchAll:", rawComponent.fields.matchAll);

        const render = rawComponent.fields.matchAll
          ? flightRulesMatch && userRulesMatch // Both must match if matchAll is true
          : flightRulesMatch || userRulesMatch; // Either must match if matchAll is false

        console.log("Final render decision:", render);
        return render;
      }

      return true; // Default to rendering the component if no fields are present
    },
    async processFlightRules(rules, matchAll) {
      for (const rule of rules) {
        const ruleData = await this.fetchRuleData(rule.id);
        if (!ruleData) {
          continue;
        }

        const minutesRemaining = this.calculateMinutesRemaining(
          this.flightData.aggregatedDateTime
        );
        const ruleMatches = this.evaluateFlightRules(
          ruleData,
          minutesRemaining
        );

        if (matchAll) {
          if (!ruleMatches) return false; // Exit early if any rule fails
        } else {
          if (ruleMatches) return true; // Exit early if any rule matches
        }
      }

      return matchAll; // Return true if matchAll is true and all rules matched
    },
    async processUserRules(userRule) {
      const userRuleID = userRule.id;

      console.log("Processing user rule with ID:", userRuleID); // Log the user rule ID

      const userRuleData = await this.fetchUserRuleData(userRuleID);
      console.log("Fetched user rule data:", userRuleData); // Log the fetched rule data

      if (navigator.geolocation) {
        console.log("Geolocation is supported, getting user location...");

        return new Promise((resolve) => {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const userLat = position.coords.latitude;
              const userLong = position.coords.longitude;

              console.log("User location obtained:", {
                latitude: userLat,
                longitude: userLong,
              });

              const calculatedDistance = this.calculateDistanceFromUser(
                userLat,
                userLong,
                userRuleData.targetLat,
                userRuleData.targetLong
              );

              console.log(
                "Calculated distance from user to target:",
                calculatedDistance
              );

              console.log("Distance From:", userRuleData.distanceFrom);
              console.log("Distance To:", userRuleData.distanceTo);

              const userMatches =
                calculatedDistance >= userRuleData.distanceFrom &&
                calculatedDistance < userRuleData.distanceTo;

              console.log(
                `Does user's distance match? ${userMatches ? "Yes" : "No"}`
              );

              resolve(userMatches);
            },
            (error) => {
              console.error("Error getting user location:", error);
              if (error.code === error.PERMISSION_DENIED) {
                console.warn("Geolocation access denied by the user.");
              }
              resolve(false); // If geolocation fails, assume user rule doesn't match
            }
          );
        });
      } else {
        console.error("Geolocation is not supported by this browser.");
        return false; // Return false if geolocation is unsupported
      }
    },
    calculateMinutesRemaining(aggregatedDateTime) {
      const dateTime = new Date(aggregatedDateTime);
      return (dateTime - this.serverTime) / (1000 * 60);
    },
    evaluateFlightRules(ruleData, minutesRemaining) {
      const flightCodes = ruleData.flightCodes
        ? ruleData.flightCodes.toLowerCase().split(",")
        : [];
      const airlineCodes = ruleData.airlineCodes
        ? ruleData.airlineCodes.toLowerCase().split(",")
        : [];
      const airportCodes = ruleData.airportCodes
        ? ruleData.airportCodes.toLowerCase().split(",")
        : [];

      const flightNumber = this.flightData.flightNumber
        ? this.flightData.flightNumber.toLowerCase()
        : "";
      const airlineCode = this.flightData.airlineCode
        ? this.flightData.airlineCode.toLowerCase()
        : "";
      const locationCode = this.flightData.locationCode
        ? this.flightData.locationCode.toLowerCase()
        : "";

      return (
        flightCodes.includes(flightNumber) ||
        airlineCodes.includes(airlineCode) ||
        airportCodes.includes(locationCode) ||
        (ruleData.minutesRemainingFrom <= minutesRemaining &&
          minutesRemaining <= ruleData.minutesRemainingTo)
      );
    },
    calculateDistanceFromUser(sourceLat, sourceLong, targetLat, targetLong) {
      const earthRadius = 6371;
      const diffLat = this.degreesToRadians(targetLat - sourceLat);
      const diffLon = this.degreesToRadians(targetLong - sourceLong);
      const a =
        Math.sin(diffLat / 2) * Math.sin(diffLat / 2) +
        Math.sin(diffLon / 2) *
          Math.sin(diffLon / 2) *
          Math.cos(this.degreesToRadians(sourceLat)) *
          Math.cos(this.degreesToRadians(targetLat));
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = earthRadius * c;

      return distance;
    },
    fetchRuleData(ruleId) {
      return axios
        .get(
          `${process.env.VUE_APP_REMOTEBASEURL}/api/TimelinePersonalisationApi/GetFlightRuleContentById`,
          {
            params: { id: ruleId },
          }
        )
        .then((response) => response.data)
        .catch((error) => {
          console.error("Error retrieving flight rule data:", error);
          throw error;
        });
    },
    fetchUserRuleData(userRuleID) {
      return axios
        .get(
          `${process.env.VUE_APP_REMOTEBASEURL}/api/TimelinePersonalisationApi/GetUserRuleContentById`,
          {
            params: { id: userRuleID },
          }
        )
        .then((response) => response.data)
        .catch((error) => {
          console.error("Error retrieving user rule data:", error);
          throw error;
        });
    },
    async fetchFlightData() {
      const flightCode = this.$route.query["flightCode"];
      const flightDateTime = this.$route.query["flightDateTime"];
      const flightDirection = this.$route.query["flightDirection"];
      const iataCode = process.env.VUE_APP_IATACODE;

      try {
        this.serverTime = await this.fetchServerTime();
        const response = await axios.get(
          `${process.env.VUE_APP_REMOTEBASEURL}/api/LiveFlightsApi/RetrieveSingleFlight`,
          {
            params: { iataCode, flightCode, flightDateTime, flightDirection },
          }
        );
        this.flightData = response.data; // Store the flight data
        this.initializeRenderStatus(); // Initialize render status after fetching flight data
      } catch (error) {
        console.error("Error retrieving flight data:", error);
      }
    },
    degreesToRadians(degrees) {
      return (degrees * Math.PI) / 180;
    },
  },
  watch: {
    flightData(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.initializeRenderStatus();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// Main timeline layout
.content-timeline {
  width: 100%;
  max-width: 110rem;
  padding: 0 2rem;
  margin: 2rem auto 0 auto;

  @include breakpoint($desktop) {
    padding: 0;
  }
}

.timeline-marker {
  margin-bottom: 5rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.content-timeline__header {
  position: sticky;
  top: 0;
  z-index: 1;
}

.content-timeline__main {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.content-timeline__timeline {
  grid-area: 1 / 1 / 2 / 13;

  @include breakpoint($responsive-nav) {
    grid-area: 1 / 1 / 2 / 10;
  }
}

// Section styles
.timeline__section {
  scroll-margin-top: 24rem;
  margin-bottom: 5rem;

  &:first-child {
    padding-top: 3rem;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @include breakpoint($desktop-sml) {
    scroll-margin-top: 17rem;
  }
}

.content-timeline__body {
  display: grid;
  position: relative;
  grid-template-columns: 40px 1fr;

  @include breakpoint($responsive-nav) {
    grid-template-columns: 80px 1fr;
  }
}

.content-timeline__timeline-track {
  display: grid;
  place-items: center;
  position: relative;

  @media screen and (max-width: $responsive-nav) {
    height: 100% !important;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0.3rem;
    height: 100%;
    z-index: -1;
    background-image: $timeline-track-background;
  }
}

.content-timeline__timeline-inner {
  display: grid;
  grid-template-columns: auto;

  @include breakpoint($responsive-nav) {
    grid-template-columns: repeat(10, 1fr);
  }
}

.timeline__section-header {
  display: grid;
  transform: translateX(-4rem);
  padding: 3rem 0;
  grid-template-columns: 40px 1fr;

  @include breakpoint($responsive-nav) {
    grid-template-columns: 80px 1fr;
    transform: translateX(-8rem);
  }
}

.timeline__section-number {
  place-items: center;
  display: grid;

  span {
    display: grid;
    place-items: center;
    color: white;
    font-size: 1.4rem;
    width: 3rem;
    height: 3rem;
    background-color: $timeline-numbers-bg;
    border-radius: 99999px;
    position: relative;

    @include breakpoint($responsive-nav) {
      width: 5rem;
      height: 5rem;
      font-size: 2rem;
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 3.5rem;
      height: 3.5rem;
      border-radius: 99999px;
      background-color: #ffffff;
      z-index: -1;

      @include breakpoint($responsive-nav) {
        width: 6rem;
        height: 6rem;
      }
    }
  }
}
.timeline__section-title {
  display: flex;
  align-items: center;

  h2 {
    margin: 0;
  }
}

.timeline__section-body-content {
  display: grid;
  transform: translateX(-4rem);
  grid-template-columns: 1fr;
  width: calc(100% + 4rem);

  @include breakpoint($responsive-nav) {
    grid-template-columns: 80px 1fr;
    transform: translateX(-8rem);
    width: 100%;
  }
}
.timeline__section-marker {
  display: none;

  @include breakpoint($responsive-nav) {
    display: grid;
    place-items: center;
  }

  span {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: $timeline-numbers-bg;
    align-self: flex-start;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 3rem;
      height: 3rem;
      border-radius: 99999px;
      background-color: #ffffff;
      z-index: -1;
    }
  }
}

// Sidebar
.content-timeline__sidebar {
  display: none;

  @include breakpoint($responsive-nav) {
    display: block;
    grid-area: 1 / 10 / 2 / 13;
  }
}

.content-timeline__links {
  border-top: 6px solid $timeline-numbers-bg;
  background: $live-flights-header-bg;
  padding: 3rem 1.5rem;
  position: sticky;
  top: 25rem;
  margin-top: 6rem;

  @include breakpoint($desktop-sml) {
    top: 19rem;
  }
}

.content-timeline__links-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-left: 3rem;
}

.content-timeline__links-item {
  display: list-item;
  font-size: 1.8rem;
  color: $timeline-panel-sidebar-links;
}

.content-timeline__link {
  padding: 0;
  line-height: 1.4;
  display: block;
  text-decoration: none;
}

// Shared Panel Styling
.timeline-panel {
  width: 100%;
  padding: 3rem;
  border: 1px solid $timeline-panel-border;

  &--filled {
    background: $timeline-panel--dark-bg;
    color: $white;
    border: none;

    ::v-deep h3 {
      color: $white !important;

      span {
        background: $white !important;
      }
    }
  }

  ::v-deep h3 {
    position: relative;
    font-size: 1.6rem !important;
    font-family: $futura;
    font-weight: 400;
    margin-bottom: 1rem;

    align-items: center;
    display: flex;

    span {
      display: block;
      width: 4rem;
      height: 4rem;
      margin: 0 1rem 0 0;
      border-radius: 50%;
      background: $timeline-panel-circle-bg;
      position: relative;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        display: block;
        max-width: 2.5rem;
        max-height: 2.5rem;
      }
    }
  }

  ::v-deep .timeline-panel__copy {
    padding: 3rem;
  }

  ::v-deep .btn {
    width: 100%;

    @include breakpoint($tablet-sml) {
      width: auto;
    }
  }
}
</style>
