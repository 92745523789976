<template>
  <section
    class="modal--emergency"
    role="dialog"
    aria-labelledby="modal-label"
    aria-hidden="false"
    data-stackable="false"
  >
    <header class="header">
      <h2>Explosion outside main terminal</h2>
    </header>
    <p>
      There has been a small <a href="#">explosion outside</a> the main terminal
      building. A police operation is underway and the airport has been
      evacuated. All operations will stop until further notice.
    </p>
    <p>CALL 999 FOR ASSISTANCE OR MORE INFORMATION</p>
  </section>
</template>

<script>
export default {
  name: "EmergencyModal",
};
</script>

<style lang="scss" scoped>
.modal--emergency {
  background: $alert-warning !important;
  color: $white !important;

  h2 {
    color: $white !important;
  }
}
</style>
