<template>
  <section id="GetYourGuideObj">
    <div v-html="getYourGuideObjhtml" id="GetYourGuideScript"></div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "GetYourGuideComponent",
  props: ["selectedLanguage", "componentData", "pageInfo"],
  data() {
    return {
      webpage: "",
      getYourGuideObjhtml: "",
    };
  },
  computed: {},
  mounted() {
    this.loadGetYourGuideWidgetObj();
  },
  methods: {
    loadGetYourGuideWidgetObj() {
      const apiUrl =
        process.env.VUE_APP_REMOTEBASEURL +
        `/api/GetYourGuideApi/GetYourGuideObj?location=` +
        this.pageInfo.fields.title +
        `, ` +
        this.pageInfo.fields.subtitle +
        `&locationCode=` +
        this.pageInfo.fields.locationCode +
        `&iataCode=${process.env.VUE_APP_IATACODE}`;
      axios
        .get(apiUrl)
        .then((response) => {
          this.getYourGuideObjhtml = response.data.html;
          console.log("response : " + response.data.script);
          let divScripts = document.getElementById("GetYourGuideScript");
          let newScript = document.createElement("script");
          newScript.src = response.data.script; //'https://widget.getyourguide.com/v2/widget.js'
          divScripts.appendChild(newScript);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
/* Center the content */
#CarHireIframe {
  height: 250px;
}
</style>
