<template>
  <section
    class="modal--general"
    role="dialog"
    aria-labelledby="modal-label"
    aria-hidden="false"
    data-stackable="false"
  >
    <header class="header">
      <h2>General Modal</h2>
    </header>
    <p>
      Thick fog has lead to the cancellation of several flights, please contact
      the airline before travelling.
    </p>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Necessitatibus
    </p>
  </section>
</template>

<script>
export default {
  name: "GeneralModal",
};
</script>
