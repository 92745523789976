<template>
  <div
    class="hero-slider__slide"
    :style="{
      backgroundImage:
        'url(' +
        heroData.fields.liveFightsHeroImage.fields.umbracoFile.src +
        ')',
    }"
  >
    <LiveFlightsForm :heroData="heroData" />
    <CurrencyForm
      :heroData="heroData"
      :liveFlightsSiteSettings="herosSiteSettings"
    />
    <div
      :class="heroData.fields.liveFlightsApplyGradient ? 'hero__gradient' : ''"
    ></div>
  </div>
</template>

<script>
import CurrencyForm from "@/components/InnerForms/LiveFlights.vue";

export default {
  name: "HeroLiveFlights",
  props: ["heroData", "herosSiteSettings"],
  components: {
    CurrencyForm,
  },
};
</script>

<style lang="scss"></style>
