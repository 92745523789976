import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import ContentPage from "../views/ContentPage.vue";
import Styleguide from "../views/Styleguide.vue";
import SingleComponentDemoPage from "../views/SingleComponentDemoPage.vue";
import FlightStatusPage from "../views/FlightStatusPage.vue";
import TimelinePanelsPage from "../views/TimelinePanels.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    production: false,
  },
  {
    path: "/content-page/",
    name: "Content Page",
    component: ContentPage,
    production: false,
  },
  {
    path: "/styleguide/",
    name: "Styleguide Page",
    component: Styleguide,
    production: false,
  },
  {
    path: "/flight-status/",
    name: "Flight Status Page",
    component: FlightStatusPage,
    production: false,
  },
  {
    path: "/timeline-panels",
    name: "Timeline Panels",
    component: TimelinePanelsPage,
    production: false,
  },
  /* Routing for components which require data supplied from the parent */
  {
    path: "/components/:slug(.*)",
    name: "Dynamic Component Route",
    component: SingleComponentDemoPage,
    production: false,
  },
  /* Catchall page route - greediest, so always put this last*/
  {
    path: "/:slug(.*)",
    name: "Dynamic Page Route",
    component: ContentPage,
    production: true,
  },
];

// Use this to only include routes marked 'production: true' on npm run build. This avoids building index page for production
const filteredRoutes =
  process.env.NODE_ENV === "production"
    ? routes.filter((route) => {
        return route.production;
      })
    : routes;

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: filteredRoutes,
});

export default router;
