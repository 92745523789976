// This mixin essentially calculates a challenge field to go back with form submissions. Logic is split to here to allow for updating over time
export default {
  computed: {
    calculatedResponse: function () {
      return ((Date.now() / 1000) * 2).toFixed(0) - 30000;
    },
  },
  data() {
    return {};
  },
};
