<template>
  <div
    class="
      timeline-panel timeline-panel--filled
      timeline-carhire timeline-marker
    "
  >
    <h3>
      <span>
        <img
          v-if="componentData.fields.icon != null"
          :src="componentData.fields.icon.fields.umbracoFile.src"
        />
      </span>
      {{ componentData.fields.title }}
    </h3>
    <div class="timeline-carhire-form">
      <div class="grid">
        <div class="grid__full">
          <label for="pickUpLocation">Pick-up location</label>
          <Select2
            name="pickUpLocation"
            :options="airports"
            :settings="{
              placeholder: 'Please select',
            }"
            v-model="pickUpLocation"
          />
        </div>
      </div>
      <div class="grid">
        <div class="grid__half">
          <label for="pickUpDate">PICK-UP DATE</label>
          <DatePicker
            v-model="pickUpDate"
            :min-date="new Date()"
            class="datepicker"
            :masks="{ input: displayFormat }"
            :popover="{ visibility: 'focus' }"
            is-required
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input :value="inputValue || `Date`" v-on="inputEvents" />
            </template>
          </DatePicker>
        </div>
        <div class="grid__half">
          <label for="exitTime">TIME</label>
          <div class="grid-times">
            <div class="col-time-hours">
              <label for="pickUpHour" class="hiddenFromView"
                >Pick-up time hours
              </label>
              <Select2
                name="pickUpHour"
                :options="[
                  '00',
                  '01',
                  '02',
                  '03',
                  '04',
                  '05',
                  '06',
                  '07',
                  '08',
                  '09',
                  '10',
                  '11',
                  '12',
                  '13',
                  '14',
                  '15',
                  '16',
                  '17',
                  '18',
                  '19',
                  '20',
                  '21',
                  '22',
                  '23',
                ]"
                v-model="pickUpHour"
              />
            </div>
            <div class="col-time-mins">
              <label for="pickUpMinute" class="hiddenFromView"
                >Pick-up time minutes</label
              >
              <Select2
                name="pickUpMinute"
                :options="['00', '15', '30', '45']"
                v-model="pickUpMinute"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="grid">
        <div class="grid__half">
          <label for="dropOffDate">DROP-OFF DATE</label>
          <DatePicker
            v-model="dropOffDate"
            :min-date="new Date()"
            class="datepicker"
            :masks="{ input: displayFormat }"
            :popover="{ visibility: 'focus' }"
            is-required
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input :value="inputValue || `Date`" v-on="inputEvents" />
            </template>
          </DatePicker>
        </div>
        <div class="grid__half">
          <label for="entryTime">TIME</label>
          <div class="grid-times">
            <div class="col-time-hours">
              <label for="dropOffHour" class="hiddenFromView"
                >Drop-off time hours
              </label>
              <Select2
                name="dropOffHour"
                :options="[
                  '00',
                  '01',
                  '02',
                  '03',
                  '04',
                  '05',
                  '06',
                  '07',
                  '08',
                  '09',
                  '10',
                  '11',
                  '12',
                  '13',
                  '14',
                  '15',
                  '16',
                  '17',
                  '18',
                  '19',
                  '20',
                  '21',
                  '22',
                  '23',
                ]"
                v-model="dropOffHour"
              />
            </div>
            <div class="col-time-mins">
              <label for="dropOffMinute" class="hiddenFromView">
                >Drop-off time minutes</label
              >
              <Select2
                name="dropOffMinute"
                :options="['00', '15', '30', '45']"
                v-model="dropOffMinute"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <a href="#" class="btn btn--primary" @click="bookCarHire()">Book Now</a>
  </div>
</template>

<script>
import Select2 from "vue3-select2-component";
import { DatePicker } from "v-calendar";
import axios from "axios";
import dayjs from "dayjs";

export default {
  name: "TimelineCarHirePanel",
  props: ["componentData"],
  inject: ["publicSiteSettings"],
  components: {
    Select2,
    DatePicker,
  },
  data() {
    return {
      airports: [],
      pickUpDate: "",
      dropOffDate: "",
      displayFormat: "DD-MM-YYYY",
      pickUpHour: "00",
      pickUpMinute: "00",
      dropOffHour: "00",
      dropOffMinute: "00",
    };
  },
  mounted() {
    this.loadLocations();
  },
  methods: {
    loadLocations() {
      this.webpage = this.$route.params.slug
        ? "/" + this.$route.params.slug.replace(/\/?$/, "/")
        : "/";

      const apiUrl =
        process.env.VUE_APP_REMOTEBASEURL +
        `/api/AirportsApi/GetAirportsLite?url=/airports/&iataCode=${process.env.VUE_APP_IATACODE}&cultureCode=${this.selectedLanguage}`;

      axios
        .get(apiUrl)
        .then((response) => {
          this.airports = response.data.airports;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formatDate(date) {
      return dayjs(date).format(this.displayFormat);
    },
    bookCarHire() {
      const hc_pickUpTimeString =
        this.pickUpHour + ":" + this.pickUpMinute + ":00";
      const hc_dropOffTimeString =
        this.dropOffHour + ":" + this.dropOffMinute + ":00";
      const apiUrl =
        process.env.VUE_APP_REMOTEBASEURL + `/api/CarHireApi/GetCarsAvailable`;

      axios({
        method: "post",
        url: apiUrl,
        headers: {},
        data: {
          pickUpLocation: this.pickUpLocation,
          dropOffLocation: this.pickUpLocation,
          pickUpDate: this.formatDate(this.pickUpDate),
          pickUpTime: hc_pickUpTimeString,
          returnDate: this.formatDate(this.dropOffDate),
          returnTime: hc_dropOffTimeString,
          iataCode: process.env.VUE_APP_IATACODE,
          cultureCode: this.selectedLanguage,
        },
      })
        .then((response) => {
          var responseUrl = response.data;
          window.open(responseUrl);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.timeline-carhire {
  .timeline-carhire-form {
    margin-top: 30px;

    .grid {
      margin-bottom: 30px;
    }

    label {
      font-size: 1.8rem;
      color: $white;
    }

    .grid-times {
      display: flex;

      .col-time-hours,
      .col-time-mins {
        width: 50%;
      }
    }

    .grid__half:nth-child(2) {
      margin-top: 30px;

      @include breakpoint($tablet) {
        margin-top: 0;
      }
    }

    .hiddenFromView {
      //https://stackoverflow.com/a/26032207
      position: absolute !important; /* Outside the DOM flow */
      height: 1px;
      width: 1px; /* Nearly collapsed */
      overflow: hidden;
      clip: rect(1px 1px 1px 1px); /* IE 7+ only support clip without commas */
      clip: rect(1px, 1px, 1px, 1px); /* All other browsers */
    }
  }
}
</style>
