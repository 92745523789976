<template>
  <section
    class="modal--newsletter"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modal-label"
    aria-hidden="false"
    data-stackable="false"
  >
    <div v-if="!formSubmitted">
      <header class="header" v-if="modalHeaderText">
        <h2>{{ modalHeaderText }}</h2>
        <p>{{ modalLabelText }}</p>
      </header>

      <form name="forms.watch" class="form" novalidate="">
        <div class="form__row">
          <input
            type="email"
            placeholder="Enter your email address"
            v-model="email"
            required="required"
          />
        </div>
        <div class="form__row checkbox">
          <input
            id="watch-flight-email-opt-in"
            type="checkbox"
            v-model="optIn"
          />

          <label
            for="watch-flight-email-opt-in"
            v-html="emailModalOptin"
          ></label>
        </div>
        <div class="form__ctas">
          <input
            type="button"
            class="btn btn--primary"
            value="Receive Updates"
            @click="submitForm"
          />
        </div>
      </form>
    </div>
    <div v-if="formSubmitted">
      <p>
        {{ emailModalSuccess }}
      </p>
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "WatchFlightModal",
  props: [
    "modalHeaderText",
    "modalLabelText",
    "emailModalOptin",
    "emailModalSuccess",
    "flightNumber",
    "flightDateTime",
  ],
  data() {
    return {
      email: "",
      optIn: false,
      formSubmitted: false,
    };
  },
  methods: {
    submitForm() {
      const payload = {
        emailAddress: this.email,
        flightNumber: this.flightNumber,
        MarketingOptIn: this.optIn,
        flightDateTime: this.flightDateTime,
        iataCode: process.env.VUE_APP_IATACODE,
      };

      axios
        .post(
          process.env.VUE_APP_REMOTEBASEURL +
            "/api/LiveFlightsApi/RegisterFlightUpdateEmails",
          payload
        )
        .then((response) => {
          this.formSubmitted = true;
          console.log("Registration successful", response.data);
        })
        .catch((error) => {
          console.error("Registration failed", error.response.data);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal--newsletter {
  text-align: left;
}

.form__ctas {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @include breakpoint($tablet-sml) {
    flex-direction: row;
    gap: 3rem;
  }
}
</style>
