<template>
    <section class="panel-padding">
    <div class="inner">
        <h2 class="heading" v-if="travelInsurancePinnedBookingBarSettings.fields.travelInsuranceHeading"> {{ travelInsurancePinnedBookingBarSettings.fields.travelInsuranceHeading }} </h2>
      <div v-if="travelInsurancePinnedBookingBarSettings.fields.travelInsuranceContentField" class="rich-text-block" v-html="travelInsurancePinnedBookingBarSettings.fields.travelInsuranceContentField"></div>
      <a class="btn btn--primary"
        v-if="travelInsurancePinnedBookingBarSettings.fields.travelInsuranceLink?.url"
        :href="travelInsurancePinnedBookingBarSettings.fields.travelInsuranceLink?.url"
        :target="travelInsurancePinnedBookingBarSettings.fields.travelInsuranceLink?.target"
          >
          {{ travelInsurancePinnedBookingBarSettings.fields.travelInsuranceLink?.name }}
     </a>
    </div>
  </section>
</template>
 
<script>
 
  export default {
    name: "StickyBookingBarTravelInsurance",
    props: {
        travelInsurancePinnedBookingBarSettings: Object,
        selectedLanguage: String,
    },
    methods: {
      hideKeyboardOnMobile() {
        if (document.body.classList.contains("has-overlay")) {
          return "readonly";
        }
        return false;
      },
    },
  };
</script>

<style scoped lang="scss">
.heading {
  color: white;
}
</style>