<template>
  <div class="skyscanner-wrapper">
    <div class="inner">
      <div
        v-html="hotelIframeObjhtml"
        id="hotelIframe"
        data-skyscanner-widget="HotelSearchWidget"
      ></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "HotelIframeComponent",
  props: ["selectedLanguage", "componentData", "pageInfo"],
  data() {
    return {
      webpage: "",
      hotelIframeObjhtml: "",
      buttonColor: "",
    };
  },
  computed: {},
  mounted() {
    this.loadHotelIframeObj();
  },
  methods: {
    loadHotelIframeObj() {
      const apiUrl =
        process.env.VUE_APP_REMOTEBASEURL +
        `/api/HotelIframeApi/HotelIframeObj?` +
        `&iataCode=${process.env.VUE_APP_IATACODE}`;
      axios
        .get(apiUrl)
        .then((response) => {
          this.hotelIframeObjhtml = response.data.html;
          if (response.data.script != "") {
            let divScripts = document.getElementById("hotelIframe");
            let newScript = document.createElement("script");
            newScript.src = response.data.script;
            newScript.async = true;
            divScripts.setAttribute(
              "data-button-colour",
              this.getButtonColor()
            );
            divScripts.appendChild(newScript);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getButtonColor() {
      const button = document.querySelector(
        ".book-a-taxi button[type='submit'] "
      );
      const waka = window.getComputedStyle(button).backgroundColor;
      const hexColor =
        "#" +
        waka
          .match(/\d+/g)
          .map((x) => parseInt(x).toString(16).padStart(2, "0"))
          .join("");

      return hexColor;
    },
  },
};
</script>

<style lang="scss" scoped>
.skyscanner-wrapper {
  background: #f4f7ff;
  border-radius: 12px;
  padding: 3rem 2rem;
  margin: 4rem 0;
}
</style>
