<template>
  <section class="hotels-widget">
    <div class="form form--inline">
      <div
        v-html="pbbhotelIframeObjhtml"
        id="pbbhotelIframe"
        class="hotelIframeSection"
      ></div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "StickyBookingBarHotelsIFrame",
  props: ["selectedLanguage", "componentData", "pageInfo"],
  data() {
    return {
      webpage: "",
      pbbhotelIframeObjhtml: "",
    };
  },
  components: {},
  computed: {},
  mounted() {
    this.loadHotelIframeObj();
  },
  watch: {
    selectedLanguage(newVal, oldVal) {
      if (newVal !== oldVal) {
        //this.loadDestinations(); // Re-fetch destinations on language change
      }
    },
  },
  methods: {
    loadHotelIframeObj() {
      const apiUrl =
        process.env.VUE_APP_REMOTEBASEURL +
        `/api/HotelIframeApi/HotelIframeObj?` +
        `&iataCode=${process.env.VUE_APP_IATACODE}`;
      axios
        .get(apiUrl)
        .then((response) => {
          this.pbbhotelIframeObjhtml = response.data.html;
          if (response.data.script != "") {
            let divScripts = document.getElementById("pbbhotelIframe");
            let newScript = document.createElement("script");
            newScript.src = response.data.script;
            newScript.async = true;
            divScripts.appendChild(newScript);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    hideKeyboardOnMobile() {
      if (document.body.classList.contains("has-overlay")) {
        return "readonly";
      }
      return false;
    },
  },
};
</script>
