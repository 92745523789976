<template>
  <div
    v-if="isOpen"
    :class="['modal-mask', modalClass]"
    @keyup.esc="$emit('modal-close')"
    ref="modal"
    tabindex="0"
  >
    <div class="modal-inner">
      <div class="modal-content">
        <slot></slot>
        <button
          href="#"
          title="Close this modal"
          data-dismiss="modal"
          data-close="Close"
          class="modal-close"
          @click.stop="$emit('modal-close')"
        >
          <SvgIcon name="close" class="modal-close-icon" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/Icon";

const overlayClass = "has-overlay";
const classListEl = document.body.classList;

export default {
  name: "ModalComponent",
  props: ["isOpen", "modalClass"],
  emits: ["modal-close"],
  components: {
    SvgIcon,
  },
  updated() {
    if (classListEl.contains(overlayClass)) {
      return classListEl.remove(overlayClass);
    }

    classListEl.add(overlayClass);

    if (this.$refs?.modal) {
      this.$refs.modal.focus();
    }
  },
  methods: {
    closeModal(event) {
      if (event.target.className.includes("modal-mask")) {
        this.$emit("modal-close");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
  overflow: scroll;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000, $alpha: 0.85);

  &.newslettermodal,
  &.watchflightmodal {
    background: rgba($color: $skin-colour-primary, $alpha: 0.85);
  }
}
.modal-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 95%;
  max-width: 66rem;
  // overflow-x: hidden;
  // overflow-y: auto;
  overflow: visible;
  transform: translate(-50%, -50%);
  background-color: $white;
  border-radius: 2px;
  text-align: center;
}
.modal-content {
  overflow: visible;
  height: 100%;
  width: 100%;
  max-height: 90vh;

  @include breakpoint($tablet-sml) {
    height: auto;
  }

  section {
    padding: 4rem 5rem;
  }
}
.modal-close-icon {
  color: $skin-colour-primary;
}
.modal-close {
  position: absolute;
  top: 2rem;
  right: 1.5rem;
  display: block;
  width: 1.8rem;
  height: 1.8rem;
}
</style>
