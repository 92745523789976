<template>
  <div class="alerts" :class="alertClass">
    <div class="alerts__header">
      <SvgIcon
        :name="color === 'red' ? 'exclamation-triangle' : 'exclamation-circle'"
      />
      <span class="alerts__title">
        {{ alerts.length }} {{ color }}
        {{ alerts.length > 1 ? "Alerts" : "Alert" }}
      </span>
    </div>
    <ul class="alerts__list">
      <li
        class="alerts__list-item"
        v-for="alert in alerts"
        :key="alert.system.id"
      >
        <p class="alerts__alert-text">{{ alert.fields.alertText }}</p>
        <div class="alerts__footer">
          <a
            class="alerts__link"
            v-if="alert.fields.link"
            :href="alert.fields.link.url"
          >
            <span>
              {{ alert.fields.link.name }}
            </span>
            <SvgIcon name="chevron-down" class="close-icon" />
          </a>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import SvgIcon from "@/components/Icon";

export default {
  props: {
    alerts: {
      type: Array,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
  components: {
    SvgIcon,
  },
  computed: {
    alertClass() {
      return `alerts--${this.color}`;
    },
  },
};
</script>

<style lang="scss">
.alerts {
  padding: 4rem 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  @include breakpoint($desktop-sml) {
    padding: 4rem 6rem;
  }

  &--red {
    background-color: #ef4444;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    .alerts__link {
      background-color: #dc2626;
    }

    .alerts__list-item {
      border-top: 1px solid #f87171;
    }
  }

  &--amber {
    background-color: #f59e0b;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    .alerts__link {
      background-color: #d97706;
    }

    .alerts__list-item {
      border-top: 1px solid #fdba74;
    }
  }

  p {
    margin: 0;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 1rem;

    .exclamation-triangle,
    .exclamation-circle {
      width: 32px;
      height: 32px;
      color: #ffffff;
    }
  }

  &__title {
    font-size: 2rem;
    font-weight: 600;
    color: #ffffff;
  }

  &__list {
    list-style-type: disc;
    display: flex;
    flex-direction: column;
  }

  &__list-item {
    list-style-type: disc;
    margin: unset !important;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    padding: 1.25rem 0;

    &:first-of-type {
      border-top: none;
    }
  }

  &__alert-text {
    font-size: 1.5rem;
    font-weight: 600;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__link {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem;
    border-radius: 4px;
    color: #ffffff;
    font-size: 1.25rem;
    font-weight: 600;
    text-decoration: none;

    .chevron-down {
      transform: rotate(-90deg);
    }
  }
}
</style>
