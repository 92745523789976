<template>
  <div class="currency-hero inner">
    <div class="currency-hero__header">
      <!-- <h1 class="hero__heading">{{ heroData.fields.heroTitle }}</h1> -->
      <h1 class="hero__heading">Find your currency</h1>
      <div class="currency-hero__exrate">
        <label class="currency-hero__label">Currency Exchange Rate</label>
        <div class="currency-hero__exrate-body">
          1 {{ localeConfig.currency }} =
          {{ staticConversionRate }}
          {{ chosenCurrency }}
        </div>
      </div>
    </div>
    <div class="hero-form hero-form--booking hero-form--mobile">
      <button type="submit" class="btn btn--primary btn--hero" tabindex="0">
        {{ this.labels.value.buttonBook }}
      </button>
    </div>
    <div class="hero-form hero-form--booking hero-form--desktop">
      <div class="currency-hero__grid">
        <div class="currency-hero__form">
          <div class="currency-hero__column currency-hero__currency">
            <div></div>
            <div>
              <label for="" class="currency-hero__label">{{
                heroData.fields.currencyFieldOne
              }}</label>
              <div class="currency-hero__body">
                <Select2
                  :options="currencyNames"
                  :settings="{
                    placeholder: currencyNames[0],
                  }"
                  :value="startExRate"
                  v-model="chosenCurrency"
                  @select="getCurrencyConversion()"
                >
                </Select2>
              </div>
            </div>
          </div>
          <div class="currency-hero__column currency-hero__inputs">
            <div class="currency-hero__input-group">
              <label for="" class="currency-hero__label"
                >{{ heroData.fields.currencyFieldTwo }}
                {{ localeConfig.currency }} ?</label
              >
              <div class="currency-hero__body">
                <input
                  inputmode="numeric"
                  class="selectbox"
                  v-model="currencyAmount"
                  placeholder="0"
                  @input="updateTotalOnChange('currencyAmount')"
                  @click="clearInput"
                />
              </div>
            </div>
            <div class="currency-hero__switcher">
              <button @click="swapCurrencies">
                <SvgIcon name="arrows-left-right" />
              </button>
            </div>
            <div class="currency-hero__input-group">
              <div class="currency-hero__body">
                <label for="" class="currency-hero__label"
                  >{{ heroData.fields.currencyFieldThree }}
                  {{ chosenCurrency }}</label
                >
                <input
                  inputmode="numeric"
                  class="selectbox"
                  v-model="currencyEstimate"
                  placeholder="0"
                  @input="updateTotalOnChange('currencyEstimate')"
                  @click="clearInput"
                />
              </div>
            </div>
          </div>
          <div class="currency-hero__column currency-hero__purchase">
            <label for="" class="currency-hero__label">&nbsp;</label>
            <div class="currency-hero__body">
              <a
                :href="
                  currencySiteSettings.fields.currencyExchangeAffiliateLink
                "
                class="btn btn--primary btn--hero"
                tabindex="0"
              >
                Get Currency
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Select2 from "vue3-select2-component";
import parkingForm from "../../mixins/parkingForm";
import axios from "axios";
import SvgIcon from "@/components/Icon";

const LOCALE_CONFIG_MAPPING = {
  "en-US": {
    currency: "$",
  },
  "en-EU": {
    currency: "€",
  },
  "en-GB": {
    currency: "£",
  },
  // add more mappings as needed
};

export default {
  name: "CurrencyForm",
  props: ["heroData", "currencySiteSettings"],
  mixins: [parkingForm],
  computed: {
    localeConfig() {
      // Return a default configuration if locale not found.
      return (
        LOCALE_CONFIG_MAPPING[this.localAreaCode] ||
        LOCALE_CONFIG_MAPPING["en-US"]
      );
    },
  },
  data() {
    return {
      chosenCurrency: "",
      currencyAmount: 0,
      currencyEstimate: 0,
      exchangeRate: 0,
      resObj: {},
      currencyNames: [],
      hasCurrencyBeenSwapped: false,
      localAreaCode: this.currencySiteSettings.fields.globalLocale,
      staticConversionRate: 0,
      startExRate: 0,
    };
  },
  methods: {
    getStaticCurrencyConversion() {
      var devideByER = 1 / this.exchangeRate;
      var total = 1 * devideByER;
      this.staticConversionRate = Number(total.toFixed(2));
    },
    getCurrencyConversion() {
      function isFirstDigitOneOrGreaterInHero(number) {
        const numberString = number.toString();
        const firstCharacter = numberString.charAt(0);
        const firstDigit = parseInt(firstCharacter);
        return firstDigit >= 1;
      }

      function countDecimalPlacesInHero(number) {
        const numberStr = number.toString();
        const decimalIndex = numberStr.indexOf(".");

        if (decimalIndex === -1) return 0;

        const decimalPart = numberStr.slice(decimalIndex + 1);

        let lastNonZeroIndex = -1;
        for (let i = decimalPart.length - 1; i >= 0; i--) {
          if (decimalPart[i] !== "0") {
            lastNonZeroIndex = i;
            break;
          }
        }
        if (lastNonZeroIndex === -1) return 0;

        return lastNonZeroIndex + 1;
      }

      for (let i = 0; i < this.currencyNames.length; i++) {
        if (this.chosenCurrency === this.currencyNames[i]) {
          this.exchangeRate = this.resObj[this.currencyNames[i]];

          if (isFirstDigitOneOrGreaterInHero(this.exchangeRate)) {
            this.exchangeRate = Number(this.exchangeRate.toFixed(2));
          } else if (countDecimalPlacesInHero(this.exchangeRate) < 4) {
            this.exchangeRate = Number(this.exchangeRate.toFixed(3));
          }
          Math.ceil(this.exchangeRate);
          this.updateTotalOnChange("currencyAmount");
          this.getStaticCurrencyConversion();
        }
      }
    },
    clearInput() {
      if (this.currencyAmount == 0) {
        return (this.currencyAmount = "");
      }
    },
    updateTotalOnChange(whichBox) {
      if (whichBox == "currencyAmount") {
        var divideByER = 1 / this.exchangeRate;
        var total = this.currencyAmount * divideByER;
        this.currencyEstimate = Number(total.toFixed(2));
      } else {
        var totalE = this.currencyEstimate * this.exchangeRate;
        this.currencyAmount = Number(totalE.toFixed(2));
      }
    },
    swapCurrencies() {
      if (this.hasCurrencyBeenSwapped == false) {
        var swapBackValue = this.currencyAmount;
        this.currencyAmount = this.currencyEstimate;
        this.hasCurrencyBeenSwapped = true;
        this.updateTotalOnChange("currencyAmount");
      } else {
        var swapValue = this.currencyAmount;
        this.currencyEstimate = this.currencyAmount;
        this.hasCurrencyBeenSwapped = false;
        this.updateTotalOnChange("currencyEstimate");
      }
    },
  },
  mounted() {
    const requestString =
      process.env.VUE_APP_REMOTEBASEURL +
      "/api/CurrencyApi/RetrieveCurrencyEstimate?iataCode=" +
      process.env.VUE_APP_IATACODE;
    axios
      .get(requestString)
      .then((response) => {
        if (response.data) {
          this.resObj = response.data.obj;
          this.currencyNames = response.data.currencyNames;
          this.exchangeRate = this.resObj[this.currencyNames[0]];
          this.chosenCurrency = this.currencyNames[0];
          this.getStaticCurrencyConversion();
        }
      })
      .catch((error) => {
        console.log("No currency data received.");
        console.log(error);
      });
  },
  components: {
    Select2,
    SvgIcon,
  },
};
</script>

<style lang="scss">
.currency-hero {
  &.inner {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 100%;
  }

  .hero-form--desktop {
    display: flex;
    align-items: center;
    width: 100%;
  }

  label {
    font-size: 1.15rem;

    @include breakpoint($desktop-sml) {
      font-size: 1.6rem;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: 100%;

    h1 {
      margin-bottom: 0;
    }
  }

  &__exrate {
    align-items: center;
    gap: 1rem;
    display: none;

    @include breakpoint($tablet) {
      display: flex;
    }

    .currency-hero__label {
      margin-bottom: 0;
    }
  }

  &__exrate-body {
    font-size: 1.5rem;
    color: $white;
    font-weight: 600;
    text-align: center;
  }

  &__grid {
    display: none;
    flex-flow: column;
    gap: 0.5rem;
    margin: 0 auto;

    @include breakpoint($tablet) {
      display: flex;
    }

    @include breakpoint($desktop) {
      gap: 1rem;
    }
  }

  &__form {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: 0px;

    @include breakpoint($desktop) {
      grid-column-gap: 6rem;
    }
  }

  &__column {
    display: grid;
    grid-template-rows: subgrid;
    grid-row: span 2;
  }

  &__label {
    font-size: 1.6rem;
    color: $white;
    font-weight: 600;
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__currency {
    label {
      margin-top: 8rem;
    }
  }

  &__inputs {
    display: flex;
    flex-flow: column;
    gap: 2rem;
    align-items: center;
    position: relative;
    transform: translateY(1.35rem);
  }

  &__input-group {
    width: 100%;

    input {
      width: 100% !important;
      color: #4c4945 !important;
      padding: 0 1rem;
      font-size: 1.75rem;
      font-weight: 500;
    }

    label:last-child {
      margin-bottom: 0;
      margin-top: 1rem;
    }
  }

  &__switcher {
    position: absolute;
    right: -1rem;
    top: 55%;
    transform: translateY(-55%) rotate(90deg);
    transition: transform 0.3s;

    &:hover {
      transform: translateY(-55%) scale(1.1) rotate(90deg);
    }

    .arrows-left-right {
      width: 3rem;
      height: 3rem;
    }
  }
}

.selectbox {
  width: 110px;
  height: 48px;
  text-align: left;
  border: 1px solid #e0e4f0;
  display: inline-block;
  color: #3272b3;
  background-color: white;

  @include breakpoint($desktop) {
    width: 172px !important;
  }

  .select2 {
    width: 100% !important;
  }

  .exRate {
    color: #3272b3;
  }
}
</style>
