<template>
  <section class="sitemap-section">
    <div class="sitemap-container">
      <h2>Sitemap</h2>
      <ul v-if="sitemap && sitemap.length">
        <SitemapItem
          v-for="(item, index) in sitemap"
          :key="index"
          :item="item"
        />
      </ul>
      <p v-else>No sitemap data available.</p>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import SitemapItem from "../Sitemap/SitemapItem.vue";

export default {
  name: "SitemapComponent",
  components: {
    SitemapItem,
  },
  props: ["selectedLanguage", "componentData"],
  data() {
    return {
      sitemap: [],
    };
  },
  mounted() {
    this.fetchSitemap();
  },
  methods: {
    fetchSitemap() {
      const apiUrl =
        process.env.VUE_APP_REMOTEBASEURL +
        `/api/sitemapapi/GetSiteMap?iataCode=${process.env.VUE_APP_IATACODE}&cultureCode=${this.selectedLanguage}`;

      axios
        .get(apiUrl)
        .then((response) => {
          this.sitemap = response.data;
        })
        .catch((error) => {
          console.error("Error fetching sitemap data:", error);
        });
    },
  },
};
</script>

<style scoped>
.sitemap-section {
  padding: 30px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.sitemap-container {
  max-width: 900px;
  margin: 0 auto;
}

ul {
  list-style-type: none;
  padding-left: 15px;
  margin-bottom: 15px;
}

li {
  margin-bottom: 12px;
}

h2 {
  font-size: 28px;
  margin-bottom: 20px;
  text-align: center;
}
</style>
