<template>
  <div
    class="
      timeline-panel timeline-panel--filled
      timeline-panel-tabs timeline-marker
    "
  >
    <ul class="resp-tabs-list">
      <li
        v-for="(item, index) in componentData.fields.tabs"
        :key="index"
        :class="['resp-tab-item', { 'resp-tab-active': index === activeIndex }]"
        :aria-controls="'tab_item-' + index"
        role="tab"
        @click="activeIndex = index"
      >
        <i class="icon"><SvgIcon :name="item.fields.icon" /></i
        >{{ item.fields.tabName }}
      </li>
    </ul>

    <div class="resp-tabs-container">
      <template v-for="(item, index) in componentData.fields.tabs" :key="index">
        <h2
          :class="[
            'resp-accordion',
            { 'resp-accordion-active': index === activeIndex },
          ]"
          :aria-controls="'tab_item-' + index"
          role="tab"
          @click="activeIndex = index"
        >
          <i class="icon"><SvgIcon :name="item.fields.icon" /></i
          >{{ item.fields.tabName }}<span class="resp-arrow"></span>
        </h2>

        <div
          class="resp-tab-content timeline-panel__copy"
          :class="{ 'resp-tab-content-active': index === activeIndex }"
          :aria-labelledby="'tab_item-' + index"
        >
          <div v-html="item.fields.tabbody"></div>
          <a
            :href="item.fields.link.url"
            v-if="item.fields.link != null && item.fields.link.url.length > 0"
            class="btn btn--primary"
            :target="item.fields.link.target"
            >{{ item.fields.link.name }}</a
          >
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/Icon";

export default {
  name: "TimelineTabbedPanel",
  props: ["componentData"],
  components: { SvgIcon },
  data() {
    return {
      activeIndex: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.timeline-panel-tabs {
  padding: 0 !important;

  .resp-tabs-list {
    display: table;
    width: 100%;
    margin: 0 !important;
    display: none;

    @include breakpoint($desktop-sml) {
      display: table !important;
    }

    li {
      display: table-cell !important;
      vertical-align: middle;
      cursor: pointer;
      text-align: center;
      padding: 8px 15px;
      line-height: 1.5;
      font-size: 1.7rem !important;
      background: $timeline-tabs-bg;
      color: $timeline-tabs-color !important;
      border: 1px solid $timeline-tabs-border;
      // border-bottom: 1px solid $skin-colour-primary;

      i {
        color: $timeline-tabs-active-bg;
      }

      &:last-child {
        border-left: 0;
      }

      &:first-child {
        border-right: 0;
      }

      &.resp-tab-active {
        color: $timeline-tabs-active-color !important;
        border-color: $skin-colour-primary;
        background: $timeline-tabs-active-bg;

        i {
          color: $timeline-tabs-active-color;
        }
      }
    }
  }

  .resp-tab-content {
    display: none;

    &.resp-tab-content-active {
      display: block;
    }
  }

  .resp-accordion {
    font-family: $futura;
    font-size: 2.2rem;
    font-weight: 100;
    line-height: 1.3;
    margin: 0px;
    padding: 10px 15px;
    background: $timeline-tabs-bg;
    color: $timeline-tabs-color;
    border: 1px solid $timeline-tabs-border;
    border-top: 0px solid $skin-colour-primary;
    cursor: pointer;

    i {
      color: $timeline-tabs-active-bg;
    }

    &:first-child {
      border-top: 1px solid $timeline-tabs-border;
      // border-top: none;
    }

    @include breakpoint($desktop-sml) {
      display: none;
    }

    &.resp-accordion-active {
      border-top: none;
      color: $timeline-tabs-active-color !important;
      border-color: $skin-colour-primary;
      background: $timeline-tabs-active-bg;
      border-bottom: 1px solid $timeline-tabs-active-color;

      i {
        color: $timeline-tabs-active-color;
      }
    }
  }

  .resp-arrow {
    width: 0;
    height: 0;
    float: right;
    margin-top: 12px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 12px solid $timeline-tabs-active-color;
  }

  .resp-accordion-active .resp-arrow {
    border: none;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 12px solid $timeline-tabs-active-color;
  }
}
</style>
