<template>
  <div class="inner panel-padding">
    <section class="article-list-small">
      <div class="article-list-small__header">
        <h2 v-if="articles == null">
          Sorry, there are currently no blog articles available
        </h2>
        <h2 v-if="componentData.fields.articleListHeader && articles != null">
          {{ componentData.fields.articleListHeader }}
        </h2>
      </div>
      <div v-if="articles != null" class="article-list-small__grid">
        <div class="article-list-small__articles">
          <h3 class="article-list-small__sub-header">
            {{ componentData.fields.articleListSubHeader }}
          </h3>
          <div class="article-list-small__article-list">
            <div v-for="(article, index) in restOfArticles" :key="index">
              <a
                :href="article.articleLink"
                title="View more"
                class="article-list-small__article"
              >
                <div class="article-list-small__article-date">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="{1.5}"
                    stroke="currentColor"
                    className="size-6"
                    data-v-4cfb636d=""
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                      data-v-4cfb636d=""
                    ></path>
                  </svg>
                  <span>{{ article.newsDate.split(" ")[0] }}</span>
                </div>
                <h4 class="article-list-small__article-title">
                  {{ article.headerTitle }}
                </h4>
                <i class="icon icon-arrow" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          <a
            v-if="componentData.fields.viewAllAnnouncementsLink"
            :href="componentData.fields.viewAllAnnouncementsLink.url"
            class="btn btn--link article-list-small__article-more"
            title="View all announcements"
          >
            <span>{{
              componentData.fields.viewAllAnnouncementsLink.name
            }}</span>
            <div class="icon">
              <SvgIcon name="arrow" />
            </div>
          </a>
        </div>
        <div class="article-list-small__featured">
          <h3 class="article-list-small__featured-title">
            {{ firstArticle.headerTitle }}
          </h3>
          <div class="article-list-small__featured-image">
            <img
              :src="firstArticle.headerImage"
              class="blog-widget__img"
              alt="Article Image"
            />
          </div>
          <p
            v-html="firstArticle.blurb"
            class="article-list-small__featured-blurb"
          ></p>
          <a
            :href="firstArticle.articleLink"
            class="btn btn--link article-list-small__featured-more"
            title="Read more"
          >
            <span>Read more</span>
            <div class="icon">
              <SvgIcon name="arrow" />
            </div>
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import SvgIcon from "@/components/Icon";

export default {
  name: "SmallArticlesList",
  props: ["selectedLanguage", "componentData"],
  components: {
    SvgIcon,
  },
  data() {
    return {
      articles: [],
      orderedArticles: [],
      firstArticle: {},
      restOfArticles: [],
      tags: [],
      contentNodePickerUrl:
        this.componentData.fields.contentNodePicker?.system.urlSegment,
    };
  },
  mounted() {
    this.loadArticles();
  },
  watch: {
    selectedLanguage: "loadArticles",
  },
  methods: {
    loadArticles() {
      this.webpage = this.$route.params.slug
        ? "/" + this.$route.params.slug.replace(/\/?$/, "/")
        : "/";

      const url =
        this.contentNodePickerUrl != null
          ? "/" + this.contentNodePickerUrl + "/"
          : this.webpage;

      const apiUrl =
        process.env.VUE_APP_REMOTEBASEURL +
        `/api/BlogPostsApi/GetAllArticles?url=${url}&iataCode=${process.env.VUE_APP_IATACODE}&cultureCode=${this.selectedLanguage}`;

      axios
        .get(apiUrl)
        .then((response) => {
          this.articles = response.data.articles;
          this.articles =
            Object.keys(this.articles).length === 0 || this.articles.length < 1
              ? null
              : this.articles;
          this.orderedArticles = response.data.articles.sort((a, b) => {
            const dateA = this.parseDateTime(a.newsDate);
            const dateB = this.parseDateTime(b.newsDate);
            return dateB - dateA;
          });
          this.firstArticle = this.orderedArticles[0];
          this.restOfArticles =
            this.orderedArticles.length >= 6
              ? this.orderedArticles.slice(1, 6)
              : this.orderedArticles.slice(1);
          this.tags = response.data.blogTags;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    parseDateTime(dateTimeStr) {
      const [dateStr, timeStr] = dateTimeStr.split(" ");
      const [day, month, year] = dateStr.split("/").map(Number);
      const [hours, minutes, seconds] = timeStr.split(":").map(Number);
      return new Date(year, month - 1, day, hours, minutes, seconds); // month is 0-based in Date constructor
    },
  },
};
</script>

<style lang="scss" scoped>
.article-list-small {
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @include breakpoint($tablet) {
    gap: 3rem;
  }

  &__header {
    display: flex;
    justify-content: center;

    h2 {
      margin: 0;
    }
  }

  &__grid {
    display: flex;
    flex-direction: column-reverse;
    gap: 4rem;
    width: 100%;
    max-width: 100rem;
    margin: 0 auto;

    @include breakpoint($tablet) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      grid-column-gap: 2rem;
    }
  }

  &__articles {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  &__sub-header {
    margin: 0;
  }

  &__article-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__article {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-decoration: none;
    border-bottom: 1px solid #f2f2f2;

    &:hover {
      transition: transform 0.2s ease-in-out;
      transform: translateY(-0.5rem);
    }
  }

  &__article-date {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    color: #afafaf;

    span {
      font-size: 1.2rem;
      font-weight: 600;
    }

    svg {
      width: 1.75rem;
      height: 1.75rem;
    }
  }

  &__article-title {
    margin: 0;
  }

  &__article-more {
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.75rem;

    @include breakpoint($tablet) {
      margin-top: 2rem;
    }

    .icon {
      transition: transform 0.3s;
    }

    &:hover {
      .icon {
        transform: translateX(0.5rem);
      }
    }
  }

  &__featured {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    &:hover {
      .article-list-small__featured-image img {
        transform: scale(1.1);
      }

      .article-list-small__featured-more {
        .icon {
          transform: translateX(0.5rem);
        }
      }
    }
  }

  &__featured-title {
    margin: 0;
  }

  &__featured-image {
    overflow: hidden;
    border-radius: 1rem;

    img {
      border-radius: 1rem;
      transition: transform 0.8s;
    }
  }

  &__featured-blurb {
    margin-bottom: 0;

    @include breakpoint($tablet) {
      margin-bottom: 2rem;
    }
  }

  &__featured-more {
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.75rem;

    .icon {
      transition: transform 0.3s;
    }
  }
}
</style>
