<template>
  <div class="content__timetable" id="anchor-fixed-timetables">
    <h2>Flight Schedule</h2>
    <div class="content__timetable--filter" data-placeholder="All">
      <select class="selectbox" id="timetableFilterMain">
        <option value="">All</option>
        <option value="1">30 days</option>
      </select>
    </div>
    <section class="flight-timetable__tab">
      <h3>
        Flights departing to
        {{ componentData.fields.destinationName }}
      </h3>
      <table>
        <tr>
          <th class="content__timetable--column-dep">Dep</th>
          <th class="content__timetable--column-dep">Arr</th>
          <th class="content__timetable--column-direction">To</th>
          <th class="content__timetable--column-airline">Airline</th>
          <th class="content__timetable--column-code">No.</th>
          <th class="content__timetable--column-day">M</th>
          <th class="content__timetable--column-day">T</th>
          <th class="content__timetable--column-day">W</th>
          <th class="content__timetable--column-day">T</th>
          <th class="content__timetable--column-day">F</th>
          <th class="content__timetable--column-day">S</th>
          <th class="content__timetable--column-day">S</th>
          <th class="content__timetable--column-dates">Dates</th>
        </tr>

        <tr
          v-for="(flight, index) in componentData.fields.departures"
          :key="index"
        >
          <td>{{ flight.fields.departureTime.substring(11, 16) }}</td>
          <td>{{ flight.fields.arrivalTime.substring(11, 16) }}</td>
          <td>{{ flight.fields.destination }}</td>
          <td>{{ flight.fields.airlineName }}</td>
          <td>{{ flight.fields.flightNumber }}</td>

          <td
            v-for="day in ['mon', 'tues', 'wed', 'thurs', 'fri', 'sat', 'sun']"
            :key="day"
          >
            <i
              v-if="
                flight.fields.dayOfTheWeek.split(',').length == 1
                  ? flight.fields.dayOfTheWeek.toLowerCase() == day
                  : flight.fields.dayOfTheWeek
                      .toLowerCase()
                      .split(',')
                      .includes(day)
              "
              class="icon icon-table-dot-blue"
            >
            </i>
          </td>

          <td class="dates">
            {{
              new Date(flight.fields.startDate)
                .toLocaleString("en-GB")
                .substring(0, 10)
            }}
            -
            {{
              new Date(flight.fields.stopDate)
                .toLocaleString("en-GB")
                .substring(0, 10)
            }}
          </td>
        </tr>
      </table>
    </section>

    <section class="flight-timetable__tab">
      <h3>
        Flights Arriving from
        {{ componentData.fields.destinationName }}
      </h3>
      <table>
        <tr>
          <th class="content__timetable--column-dep">Dep</th>
          <th class="content__timetable--column-dep">Arr</th>
          <th class="content__timetable--column-direction">From</th>
          <th class="content__timetable--column-airline">Airline</th>
          <th class="content__timetable--column-code">No.</th>
          <th class="content__timetable--column-day">M</th>
          <th class="content__timetable--column-day">T</th>
          <th class="content__timetable--column-day">W</th>
          <th class="content__timetable--column-day">T</th>
          <th class="content__timetable--column-day">F</th>
          <th class="content__timetable--column-day">S</th>
          <th class="content__timetable--column-day">S</th>
          <th class="content__timetable--column-dates">Dates</th>
        </tr>

        <tr
          v-for="(flight, index) in componentData.fields.arrivals"
          :key="index"
        >
          <td>{{ flight.fields.departureTime.substring(11, 16) }}</td>
          <td>{{ flight.fields.arrivalTime.substring(11, 16) }}</td>
          <td>{{ flight.fields.destination }}</td>
          <td>{{ flight.fields.airlineName }}</td>
          <td>{{ flight.fields.flightNumber }}</td>

          <td
            v-for="day in ['mon', 'tues', 'wed', 'thurs', 'fri', 'sat', 'sun']"
            :key="day"
          >
            <i
              v-if="
                flight.fields.dayOfTheWeek.split(',').length == 1
                  ? flight.fields.dayOfTheWeek.toLowerCase() == day
                  : flight.fields.dayOfTheWeek
                      .toLowerCase()
                      .split(',')
                      .includes(day)
              "
              class="icon icon-table-dot-blue"
            >
            </i>
          </td>

          <td class="dates">
            {{
              new Date(flight.fields.startDate)
                .toLocaleString("en-GB")
                .substring(0, 10)
            }}
            -
            {{
              new Date(flight.fields.stopDate)
                .toLocaleString("en-GB")
                .substring(0, 10)
            }}
          </td>
        </tr>
      </table>
    </section>
  </div>
</template>

<script>
export default {
  name: "ManualFlightSchedules",
  props: ["componentData"],
  methods: {
    filterTable() {
      var selectBox = document.getElementById("timetableFilterMain");
      var selectedOption = selectBox.options[selectBox.selectedIndex].value;
      var tableRows = document.querySelectorAll(
        "#anchor-fixed-timetables table tr"
      );

      function formatDate(inputDate) {
        var parts = inputDate.split("/");

        var date = new Date(parts[2], parts[1] - 1, parts[0]);

        var formattedDate = date.toLocaleString("en-US", {
          weekday: "short",
          month: "short",
          day: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          timeZoneName: "short",
        });

        return formattedDate;
      }

      if (selectedOption === "1") {
        var today = new Date();
        var thirtyDaysLater = new Date(
          today.getTime() + 30 * 24 * 60 * 60 * 1000
        );

        tableRows.forEach(function (row) {
          var departureDate = row.querySelector(".dates");
          if (departureDate != null) {
            var inputDate = departureDate.textContent.split("-")[0].trim();

            var formattedDate = formatDate(inputDate);
            if (new Date(formattedDate) <= new Date(thirtyDaysLater)) {
              row.style.display = "table-row";
            } else {
              row.style.display = "none";
            }
          }
        });
      } else {
        tableRows.forEach(function (row) {
          row.style.display = "table-row";
        });
      }
    },
  },
  mounted() {
    this.filterTable();
    document
      .getElementById("timetableFilterMain")
      .addEventListener("change", this.filterTable);
  },
};
</script>

<style lang="scss">
.content__timetable {
  h2 {
    font-size: 3.2rem;

    @include breakpoint($tablet) {
      float: left;
      margin-right: 1.5rem;
    }
  }

  h3 {
    display: none;
    padding-top: 30px;

    @include breakpoint($tablet) {
      display: block;
    }
  }

  .icon-table-dot-blue {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: lightblue;
    border-radius: 50%;
  }

  table {
    background-color: transparent;
    width: 100%;
    border-top: solid 1px #f2f3f6;
    margin-bottom: 6rem;

    tr {
      border-bottom: solid 1px #f2f3f6;

      @include breakpoint($responsive-nav-only) {
        &:last-of-type {
          border-bottom: none;
        }
      }
    }

    td:last-child,
    th:last-child {
      padding-left: 35px;
      padding-right: 0px;
      white-space: nowrap;
    }

    td {
      font-size: 1.4rem;
    }

    th {
      font-family: $futura;
      color: #9099b3;
      text-transform: uppercase;
      text-align: left;
      font-size: 1.6rem;
      padding-left: 0px;
      padding-right: 35px;
    }

    th,
    td {
      background-color: transparent;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 0px;
      border: 0px;
    }

    .icon-table-dot {
      svg circle {
        fill: #00a8e1;
      }
    }

    .content__timetable--column-day {
      padding-right: 12px;
    }

    .content__timetable--column-airline {
      min-width: 150px;
    }

    .content__timetable--column-code {
      min-width: 90px;
    }

    .content__timetable--column-direction {
      min-width: 75px;
      padding-right: 20px;
    }
  }
}

.content-wrap--full {
  .content {
    //    @include breakpoint($responsive-nav) {
    display: flex;
    flex-direction: column;
    padding: 4rem $grid-padding;

    .content__copy {
      p:last-of-type {
        margin-bottom: 0;
      }
    }

    .content__left {
      width: 100%;
      padding: 0;
      flex-basis: 100%;
      order: 0;

      .panel-padding {
        padding: 0;
      }
    }

    .content-left__read-more {
      order: 1;
    }

    .content__sidebar {
      width: 100%;
      padding: 4rem 2rem;
      border: none;
      flex-basis: 100%;
      order: 2;

      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      flex-direction: column;

      @include breakpoint($tablet) {
        flex-direction: row;
      }

      > div {
        @include breakpoint($tablet) {
          flex-basis: 48%;
        }
      }
    }

    .content__sidebar {
      margin: 3rem 0 0;
      border-radius: 12px;
      // @include breakpoint($tablet) {
      //     display: flex;
      //     justify-content: space-between;

      //     >div {
      //         flex-basis: 48%;
      //     }
      // }
    }

    .gyg-wrapper {
      padding: 0;
      margin: 6rem 0;
      max-width: none;
    }

    .book-taxis--inline {
      margin: 6rem 0;
      border-radius: 12px;
    }
  }

  &::after {
    content: none;
  }

  .content__item-wrap {
    max-width: none;

    //  @include breakpoint($tablet-sml) {
    .item--destination {
      max-width: none;
    }

    //   }

    .item {
      @include breakpoint($mobile-land) {
        width: 48%;
        float: left;

        &:nth-child(even) {
          margin-left: 4%;
        }
      }

      @include breakpoint($tablet) {
        width: 23.5%;
        margin-left: 1.5%;

        &:first-of-type {
          margin-left: 0;
        }

        &:nth-child(even) {
          margin-left: 1.5%;
        }
      }
    }
  }

  .content__copy {
    .skyscanner-widget-container {
      display: inline-block;

      a {
        display: block;
      }
    }
  }

  .content__left {
    margin-bottom: 0 !important;
  }

  .content__sidebar {
    margin-top: 0 !important;
  }

  .content__timetable table {
    margin-bottom: 6rem;
    max-width: none;
  }

  .flight-timetable__tab {
    h3 {
      padding-top: 0;
    }
  }
}
</style>
