<template>
  <section
    class="modal--warning"
    role="dialog"
    aria-labelledby="modal-label"
    aria-hidden="false"
    data-stackable="false"
  >
    <header class="header">
      <h2>
        <i class="fa fa-info-circle" data-grunticon-embed=""></i>Fog Alert
      </h2>
    </header>
    <p>
      Thick fog has lead to the cancellation of several flights, please contact
      the airline before travelling.
    </p>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Necessitatibus
    </p>
  </section>
</template>

<script>
export default {
  name: "WarningModal",
};
</script>

<style lang="scss" scoped>
.modal--warning {
  background: $alert-attention !important;
  color: $modal-warning-color !important;
}
</style>
