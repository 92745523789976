<template>
  <div class="timeline-panel timeline-marker">
    <h3>
      <span>
        <img
          v-if="componentData.fields.icon != null"
          :src="componentData.fields.icon.fields.umbracoFile.src"
        />
      </span>
      {{ componentData.fields.title }}
    </h3>
    <div v-html="componentData.fields.body"></div>
    <a
      :href="componentData.fields.link.url"
      v-if="
        componentData.fields.link != null &&
        componentData.fields.link.url.length > 0
      "
      class="btn btn--secondary"
      >{{ componentData.fields.link.name }}</a
    >
  </div>
</template>

<script>
export default {
  name: "TimelinePanelBasic",
  props: ["componentData"],
  components: {},
  mounted() {},
};
</script>
