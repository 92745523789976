<template>
  <section
    class="modal--newsletter"
    role="dialog"
    aria-labelledby="modal-label"
    aria-hidden="false"
    data-stackable="false"
  >
    <header class="header">
      <h2>Subscribe to our Newsletter</h2>
      <p>New routes & destinations, deals & special offers and much more</p>
    </header>

    <form action="" class="form ng-pristine ng-valid">
      <div class="form__row">
        <input
          type="text"
          name="newsletterFirstName"
          id="newsletterFirstName"
          placeholder="First Name"
          data-rule-required="true"
          required=""
        />
      </div>

      <div class="form__row">
        <input
          type="text"
          name="newsletterLastName"
          id="newsletterLastName"
          placeholder="Last Name"
          data-rule-required="true"
          required=""
        />
      </div>

      <div class="form__row">
        <input type="email" placeholder="Enter your email address" />

        <span
          class="field-validation-error"
          data-valmsg-for="6e1d3d76-dc66-4f6a-fa8e-bc442359579e"
          data-valmsg-replace="true"
          ><span id="6e1d3d76-dc66-4f6a-fa8e-bc442359579e-error" class=""
            >You must enter your email address</span
          ></span
        >
      </div>
      <div class="form__row checkbox">
        <div>
          <input type="checkbox" id="terms" name="terms" checked="" />
          <label for="terms" class="small-label"
            >I have read &amp; accept the
            <a href="#nogo">Terms &amp; Conditions of the service</a></label
          >
        </div>
      </div>
      <div class="form__ctas">
        <input type="button" class="btn btn--primary" value="Subscribe" />
        <a href="#!" class="btn btn--secondary">Cancel</a>
      </div>
    </form>
  </section>
</template>

<script>
export default {
  name: "NewsLetterModal",
};
</script>

<style lang="scss" scoped>
.modal--newsletter {
  text-align: left;
}

.form__ctas {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @include breakpoint($tablet-sml) {
    flex-direction: row;
    gap: 3rem;
  }
}
</style>
