import { createStore } from "vuex";

export default createStore({
  state: {
    selectedLanguage: process.env.VUE_APP_DEFAULTLANGUAGECODE || "en-us",
  },
  mutations: {
    setSelectedLanguage(state, language) {
      state.selectedLanguage = language;
    },
  },
  actions: {
    setSelectedLanguage({ commit }, language) {
      commit("setSelectedLanguage", language);
    },
  },
});
