<template>
  <div class="timeline-marker grid">
    <div
      class="grid__half"
      v-for="(item, index) in componentData.fields.blocks"
      :key="index"
    >
      <div class="timeline-panel-image-block">
        <div class="timeline-panel__offer">{{ item.fields.legend }}</div>
        <div
          v-if="item.fields.image != null"
          class="timeline-panel__img"
          :style="{
            backgroundImage:
              'url(' + item.fields.image.fields.umbracoFile.src + ')',
          }"
        ></div>
        <div class="timeline-panel__copy">
          <h3>{{ item.fields.title }}</h3>
          <a
            :href="item.fields.link.url"
            v-if="item.fields.link != null && item.fields.link.url.length > 0"
            class="btn btn--primary"
            :target="item.fields.link.target"
            >{{ item.fields.link.name }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TimelineImageBlock",
  props: ["componentData"],
  components: {},
};
</script>

<style lang="scss" scoped>
.timeline-panel-image-block {
  background: $skin-colour-primary;
  color: $white;

  h3 {
    color: $white !important;
  }

  .timeline-panel__copy {
    padding: 3rem;

    .btn {
      width: 100%;
      min-width: 0;
      margin-top: 3rem;
    }
  }

  .timeline-panel__img {
    height: 18rem;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
  }

  .timeline-panel__offer {
    position: absolute;
    left: -5px;
    top: -5px;
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: uppercase;
    color: #121212;
    background: $white;
    padding: 5px 10px;
    letter-spacing: 0;
    border: 3px solid $skin-colour-primary-bright;
  }
}

.grid__half {
  position: relative;

  &:first-child {
    margin-bottom: 5rem;

    @include breakpoint($tablet) {
      margin-bottom: 0;
    }
  }
}
</style>
