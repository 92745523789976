<template>
  <div class="timeline-panel timeline-list-block timeline-marker">
    <h3>
      <span>
        <img
          v-if="componentData.fields.icon != null"
          :src="componentData.fields.icon.fields.umbracoFile.src"
        />
      </span>
      {{ componentData.fields.title }}
    </h3>
    <ul>
      <li v-for="(item, index) in componentData.fields.items" :key="index">
        <div class="image-text-list__img">
          <img
            v-if="item.fields.icon != null"
            :src="item.fields.icon.fields.umbracoFile.src"
          />
        </div>
        <div class="image-text-list__content">
          <h4>{{ item.fields.title }}</h4>
          <div v-html="item.fields.body"></div>
        </div>
      </li>
    </ul>
    <a
      :href="componentData.fields.link.url"
      v-if="
        componentData.fields.link != null &&
        componentData.fields.link.url.length > 0
      "
      class="btn btn--secondary"
      :target="componentData.fields.link.url.target"
      >{{ componentData.fields.link.name }}</a
    >
  </div>
</template>

<script>
export default {
  name: "TimelineListBlockPanel",
  props: ["componentData"],
  components: {},
};
</script>

<style lang="scss" scoped>
.timeline-list-block {
  .image-text-list__content,
  .image-text-list__img {
    display: table-cell;
    vertical-align: top;
    margin: 0;
  }

  .image-text-list__img {
    width: 5rem;

    @include breakpoint($desktop) {
      width: 6rem;
    }

    img {
      max-width: 3rem;
      display: block;

      @include breakpoint($desktop) {
        margin: 0 auto;
        max-width: 6rem;
      }
    }
  }

  .image-text-list__content div {
    margin-left: -5rem;

    @include breakpoint($desktop) {
      margin-left: 0;
    }
  }
}
</style>
