<template>
  <li>
    <a :href="item.fullUrl" class="sitemap-link">{{ item.itemName }}</a>
    <ul v-if="item.children && item.children.length">
      <SitemapItem
        v-for="(child, index) in item.children"
        :key="index"
        :item="child"
      />
    </ul>
  </li>
</template>

<script>
export default {
  name: "SitemapItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.sitemap-link {
  text-decoration: none;
  font-size: 1.25rem;
}

ul {
  list-style-type: none;
  padding-left: 0;
  /* &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: #ccc;
  } */
}

ul ul {
  padding-left: 10px;
}

li {
  margin: 8px 0;
  line-height: 1.4;
}

ul ul li::before {
  content: "─";
  color: #999;
  margin-right: 8px;
}

ul ul ul li::before {
  content: "─";
}

ul ul ul ul li::before {
  content: "─";
}
</style>
