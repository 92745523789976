<template>
  <section class="two-col-intro typeset panel-padding">
    <div class="two-col-intro typeset">
      <div class="inner">
        <div class="two-col-intro__left">
          <h2>{{ componentData.fields.title }}</h2>
          <div
            class="rich-text-block"
            v-html="componentData.fields.intro"
          ></div>
        </div>
        <div class="two-col-intro__right">
          <h2>{{ componentData.fields.secondaryTitle }}</h2>
          <div
            class="rich-text-block"
            v-html="componentData.fields.secondaryIntro"
          ></div>
          <div v-if="componentData.fields.links">
            <ul v-for="item in componentData.fields.links" :key="item.id">
              <li>
                <a
                  class="btn btn--link"
                  :href="item.url"
                  :title="item.name"
                  :target="item.target"
                  ><i><SvgIcon :name="'arrow'" /></i>{{ item.name }}</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SvgIcon from "@/components/Icon";

export default {
  name: "TwoAreaContentBlock",
  props: ["componentData"],
  components: {
    SvgIcon,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.two-col-intro {
  h3 {
    color: $skin-colour-primary-bright;
  }
  .inner {
    @include breakpoint($tablet) {
      display: table;
      border-bottom: 1px solid $keyline;
    }
  }

  &__left,
  &__right {
    padding-top: 4rem;

    @include breakpoint($tablet) {
      padding-top: 6rem;
      padding-bottom: 4rem;
      display: table-cell;
      vertical-align: top;
    }

    ul {
      li {
        margin-bottom: 3rem !important;

        i {
          color: $skin-colour-primary-bright;
        }

        a {
          color: $skin-colour-primary-bright !important;
          font-weight: 600;
          text-decoration: none;
        }
      }
    }
  }

  &__left {
    margin-bottom: 3rem;

    @include breakpoint($tablet) {
      margin-bottom: 0;
      padding-right: 2rem;
      width: 50%;
    }

    @include breakpoint($desktop) {
      padding-right: 6rem;
      width: 60%;
    }
  }

  &__right {
    border-top: 1px solid $keyline;

    @include breakpoint($tablet) {
      border-left: 1px solid $keyline;
      border-top: none;
      padding-bottom: 3rem;
      padding-left: 2rem;
      width: 50%;
    }

    @include breakpoint($desktop) {
      padding-left: 6rem;
      width: 40%;
    }
  }
}
</style>
