<template>
  <section class="panel-padding">
    <div class="inner">
      <div class="rich-text-block" v-html="componentData.fields.body"></div>
    </div>
  </section>
</template>

<script>
export default {
  name: "RichTextBlock",
  props: ["componentData"],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
// This is content added within the CMS
.rich-text-block {
  h3:not(.big-text),
  h4:not(.big-text),
  h5:not(.big-text),
  h6:not(.big-text) {
    text-transform: uppercase;
    font-size: 1.6rem;
    color: $content-sidebar-heading;
    margin-bottom: 1rem;
  }

  h2 {
    margin-bottom: 3rem;
  }

  p {
    margin-bottom: 3rem;
    @include font-size($p-font-sizes);

    a:not(.btn) {
      color: $links;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  hr {
    height: 1px;
    background: $keyline;
    margin: 25px 0;
    display: block;
  }

  table {
    background: $light-bg;
    padding: 1.5rem 0.5rem;
    font-size: 1.5rem;
    margin: 1rem 0 3rem;
    width: 100%;
    max-width: 45rem;

    th,
    td {
      font-weight: $reg;
      padding: 0.5rem;
      text-align: center;

      .icon {
        margin: 0;
      }
    }
  }

  ul,
  ol {
    margin: 2rem 0;

    li {
      display: block;
      margin: 0 0 1.5rem;
      @include font-size($p-font-sizes);
      position: relative;
      padding-left: 1.5rem;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 6px;
        left: 0;
        background: $skin-colour-primary;
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }

      a {
        color: $btn-link;
      }
    }
  }
}
</style>
