<template>
  <section class="panel-padding">
    <div class="inner">
      <h2 class="heading centered">Airlines</h2>
      <main>
        <article
          class="item-list"
          v-for="airline in airlines"
          :key="airline.iataCode"
        >
          <div class="item-list__img">
            <img :src="airline.image" :alt="airline.title" />
          </div>
          <div class="item-list__text">
            <h3 class="pretitle">{{ airline.title }}</h3>
            <p>
              <a :href="'tel:' + airline.telephoneNumber">{{
                airline.telephoneNumber
              }}</a
              ><a :href="airline.telephoneNumber"></a><br /><a
                rel="noopener"
                :href="airline.url"
                target="_blank"
                :title="airline.title"
                >{{ airline.title }} Customer Support</a
              >
            </p>
          </div>
          <div class="item-list__btn">
            <a :href="airline.readMoreLink" class="btn btn--link"
              ><i class="icon icon-arrow" aria-hidden="true"></i>Learn More</a
            >
          </div>
        </article>
      </main>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "AirlineListComponent",
  props: ["selectedLanguage", "componentData"],
  data() {
    return {
      airlines: [],
    };
  },
  mounted() {
    this.loadAirlines();
  },
  watch: {
    selectedLanguage: "loadAirlines",
  },
  methods: {
    loadAirlines() {
      this.webpage = this.$route.params.slug
        ? "/" + this.$route.params.slug.replace(/\/?$/, "/")
        : "/";

      const apiUrl =
        process.env.VUE_APP_REMOTEBASEURL +
        `/api/AirlinesApi/GetAirlinesList?iataCode=${process.env.VUE_APP_IATACODE}&cultureCode=${this.selectedLanguage}`;

      axios
        .get(apiUrl)
        .then((response) => {
          this.airlines = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.heading.centered {
  text-align: center;
}

main,
li {
  display: block;
}

.selectbox-time + .select2-container .select2-selection__rendered {
  font-weight: 400;

  @media (max-width: 899px) {
    border: none;
    line-height: 48px;
  }
}

.item-list {
  display: flex;
  padding: 2rem 1.5rem;
  border-bottom: 1px solid #dedede;
  max-width: 114rem;
  margin: 0 auto;
  align-items: center;
  flex-direction: column;

  @media (min-width: 768px) {
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }
}

.item-list__img {
  flex-basis: 100%;
  max-width: 18rem;
  height: 12rem;
  max-height: 12rem;
  display: block;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  @media (min-width: 768px) {
    flex-basis: 16rem;
    margin-right: 2rem;
    padding-left: 0 !important;
    border-left: none !important;
  }
}

.item-list__text {
  flex-basis: 100%;
  text-align: center;

  @media (min-width: 768px) {
    flex-basis: auto;
    padding: 0 2rem 0 0;
    flex-grow: 2;
    text-align: left;
  }
}
.item-list__btn {
  flex-basis: 100%;

  @media (min-width: 768px) {
    flex-basis: 18rem;
    align-self: center;
  }

  .btn {
    color: #d42835;
    border-radius: 100px;
    padding: 1.45rem 2.5rem;
    min-width: 16rem;
    border: 1px solid #d42835;
  }
}

.btn--link {
  border-radius: 0;
  min-width: 0;
  border: none;
  padding: 0;
  font-size: 1.8rem;
  color: #d42835;
}
</style>
