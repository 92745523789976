<template>
  <div
    class="
      timeline-panel timeline-panel--filled
      timeline-insurance timeline-marker
    "
  >
    <h3>
      <span>
        <img
          v-if="componentData.fields.icon != null"
          :src="componentData.fields.icon.fields.umbracoFile.src"
        />
      </span>
      {{ componentData.fields.title }}
    </h3>
    <div class="timeline-insurance-form">
      <div class="grid">
        <label for="travelInsuranceRadioButtonTimeline"
          >WHAT TYPE OF COVER DO YOU REQUIRE?
        </label>
        <ul class="grid-radio">
          <li class="radio">
            <input
              type="radio"
              name="travelInsuranceRadioButtonTimeline"
              id="insuranceRadioBtnTimeline"
              value="0"
              checked="checked"
              data-field="trip-type"
              v-on:click="toggleInsuranceType()"
            />
            <label for="insuranceRadioBtnTimeline">Single Trip</label>
          </li>
          <li class="radio">
            <input
              type="radio"
              name="travelInsuranceRadioButtonTimeline"
              id="insuranceRadioBtnTimeline2"
              value="1"
              data-field="trip-type"
              v-on:click="toggleInsuranceType()"
            />
            <label for="insuranceRadioBtnTimeline2">Annual</label>
          </li>
        </ul>
      </div>

      <div class="grid">
        <div class="grid__full">
          <label for="hc_pickUpLocation">WHERE ARE YOU TRAVELLING TO?</label>
          <Select2
            name="destination"
            :options="destinationOptions"
            :settings="{
              placeholder: 'Please select',
            }"
            v-model="destination"
          />
        </div>
      </div>

      <div class="grid" v-if="isSingleTrip">
        <div class="grid__half">
          <label for="entryDate">DEPARTING ON</label>
          <DatePicker
            v-model="departingDate"
            :min-date="new Date()"
            class="datepicker"
            :masks="{ input: displayFormat }"
            :popover="{ visibility: 'focus' }"
            is-required
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input :value="inputValue || `Date`" v-on="inputEvents" />
            </template>
          </DatePicker>
        </div>
        <div class="grid__half">
          <label for="exitDate">RETURNING ON</label>
          <DatePicker
            v-model="returnDate"
            :min-date="new Date()"
            class="datepicker"
            :masks="{ input: displayFormat }"
            :popover="{ visibility: 'focus' }"
            is-required
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input :value="inputValue || `Date`" v-on="inputEvents" />
            </template>
          </DatePicker>
        </div>
      </div>

      <div class="grid">
        <div :class="[isSingleTrip ? 'grid__full' : 'grid__half']">
          <label for="travellers">NUMBER OF TRAVELLERS</label>
          <Select2
            id="travellers"
            name="travellers"
            :options="[1, 2, 3, 4, 5, 6, 7, 8, 9]"
            :settings="{
              placeholder: 'Please select',
            }"
            v-model="travellers"
          />
        </div>
        <div class="grid__half" v-if="!isSingleTrip">
          <label for="exitDate">START DATE?</label>
          <DatePicker
            v-model="policyStartDate"
            :min-date="new Date()"
            class="datepicker"
            :masks="{ input: displayFormat }"
            :popover="{ visibility: 'focus' }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input :value="inputValue || `Date`" v-on="inputEvents" />
            </template>
          </DatePicker>
        </div>
      </div>
    </div>

    <a href="#" @click="bookInsurance()" class="btn btn--primary"
      >Get A Quote</a
    >
  </div>
</template>

<script>
import Select2 from "vue3-select2-component";
import { DatePicker } from "v-calendar";
import axios from "axios";
import dayjs from "dayjs";

export default {
  name: "TimelineInsurancePanel",
  props: ["componentData"],
  components: {
    Select2,
    DatePicker,
  },
  data() {
    return {
      destinationOptions: [
        "Worldwide inc USA, Canada & Caribbean",
        "Worldwide exc USA, Canada & Caribbean",
        "Europe excl Spain, Cyprus, Malta, Turkey and Greece",
        "Europe incl Spain, Cyprus, Malta, Turkey and Greece",
      ],
      entryDate: "",
      exitDate: "",
      displayFormat: "DD-MM-YYYY",
      isSingleTrip: true,
    };
  },
  mounted() {},
  methods: {
    formatDateTime(incomingDate) {
      return dayjs(incomingDate).format(this.displayFormat);
    },
    toggleInsuranceType() {
      if (this.isSingleTrip) {
        this.isSingleTrip = false;
      } else {
        this.isSingleTrip = true;
      }
    },
    bookInsurance() {
      const apiUrl =
        process.env.VUE_APP_REMOTEBASEURL +
        `/api/TravelInsuranceApi/BookTravelInsurance`;

      axios({
        method: "post",
        url: apiUrl,
        headers: {},
        data: {
          tripType: this.isSingleTrip ? "Single" : "Annual",
          destination: this.destination,
          startDate: this.policyStartDate,
          departingDate: this.departingDate,
          returnDate: this.returnDate,
          numberOfTravelers: this.travellers,
        },
      })
        .then((response) => {
          console.log(response);
          window.open(response.data, "_blank");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.timeline-insurance {
  .timeline-insurance-form {
    margin-top: 30px;

    .grid {
      margin-bottom: 30px;
    }

    .grid__half {
      width: 100%;
      margin-right: 0;

      @include breakpoint($tablet) {
        width: 50%;
      }

      &:nth-child(2) {
        margin-top: 30px;

        @include breakpoint($tablet) {
          margin-top: 0;
        }
      }
    }

    label {
      font-size: 1.8rem;
      color: $white;
    }

    label[for="travelInsuranceRadioButtonTimeline"] {
      margin-bottom: 20px;
    }

    .grid-radio {
      display: flex;
      flex-direction: column;

      label {
        @include breakpoint($tablet) {
          margin-bottom: 0;
        }
      }

      li {
        margin-right: 50px;
      }

      @include breakpoint($tablet) {
        flex-direction: row;
      }
    }
  }

  .btn--primary {
    margin-top: 20px;
  }
}
</style>
