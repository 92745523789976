<template>
  <section class="large-width-frame panel-padding">
    <h2 class="heading">{{ componentData.fields.title }}</h2>

    <div class="large-width-frame__wrapper" v-if="componentData.fields.image">
      <a
        :href="componentData.fields.link.url"
        :target="componentData.fields.link.target"
        v-if="componentData.fields.link"
      >
        <img
          :src="componentData.fields.image.fields.umbracoFile.src"
          :alt="componentData.fields.image.fields.alt"
        />
      </a>
      <img
        :src="componentData.fields.image.fields.umbracoFile.src"
        :alt="componentData.fields.image.fields.alt"
        v-else
      />
    </div>
    <div
      class="large-width-frame__wrapper iframe"
      v-else-if="componentData.fields.externalPageUrl != ''"
    >
      <iframe
        :src="componentData.fields.externalPageUrl"
        frameborder="0"
      ></iframe>
    </div>
  </section>
</template>

<script>
export default {
  name: "LargeWidthFrame",
  props: ["componentData"],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.large-width-frame {
  text-align: center;
  margin: 0 auto;
  padding: 0;

  &__wrapper {
    max-width: 192rem;
    margin: 0 auto;

    &.iframe {
      position: relative;
      overflow: hidden;
      padding-top: 56.25%;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
      }
    }
  }
}
</style>
