<template>
  <section class="carrental-widget">
    <div class="form form--inline">
      <fieldset>
        <div class="grid">
          <div class="grid__third">
            <label>{{ model.settings.labels.destination }}</label>

            <Select2
              name="hc_pickUpLocationd"
              :options="destinations"
              v-model="form.destination"
              :settings="{
                placeholder: `Please select a ${model.settings.labels.destination}`,
              }"
            />
          </div>

          <div class="grid__sixth">
            <label>{{ model.settings.labels.departureDate }}</label>
            <DatePicker
              v-model="form.departureDate"
              :min-date="new Date()"
              class="datepicker"
              :popover="{ visibility: 'focus' }"
              is-required
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  :value="inputValue"
                  v-on="inputEvents"
                  :readonly="this.hideKeyboardOnMobile()"
                />
              </template>
            </DatePicker>
          </div>

          <div class="grid__sixth">
            <label>{{ model.settings.labels.nights }}</label>
            <Select2
              name="hc_pickUpHour"
              class="selectbox-time-half"
              :options="numberOfNights"
              v-model="form.nights"
            />
          </div>

          <div class="grid_adult">
            <label>{{ model.settings.labels.travellerAdult }}</label>
            <Select2
              data-field="hidden-arrival-hour"
              :options="this.numberOfTravellers"
              v-model="form.travellerAdult"
            />
          </div>
          <div class="grid_child" v-if="model.settings.includeChildTraveller">
            <label>{{ model.settings.labels.travellerChild }}</label>
            <Select2
              data-field="hidden-arrival-hour"
              :options="[...Array(7).keys()]"
              v-model="form.travellerChild"
            />
          </div>

          <div class="grid__rest">
            <a
              href="#"
              @click.prevent="
                submitButtonUrl(model.link.url, model.settings.provider)
              "
              :target="model.link.target"
              class="btn btn--primary"
              >{{ model.link.name }}</a
            >
          </div>
          <div class="grid__full">
            <label
              >{{ model.settings.labels.returnDate }}:
              {{ getReturnDate }}</label
            >
          </div>
          <div class="grid__full" v-if="inValidForm">
            <div class="booking-module__error" style="margin-top: 0">
              Sorry, please select a destination.
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  </section>
</template>
<script>
import Select2 from "vue3-select2-component";
import { DatePicker } from "v-calendar";
import dayjs from "dayjs";
import axios from "axios";

var form = {
  departureDate: new Date(),
  returnDate: new Date(),
  nights: 7,
  travellerAdult: 1,
  travellerChild: 0,
  destination: "",
};

export default {
  name: "StickyBookingBarHolidaysForm",
  components: { DatePicker, Select2 },
  data() {
    return {
      model: {
        destinations: [],
        settings: {
          labels: {},
        },
        link: {},
      },
      form,
      inValidForm: false,
    };
  },
  mounted() {
    axios
      .get(
        process.env.VUE_APP_REMOTEBASEURL +
          "/api/holidaysapi/getholidaysformmodel"
      )
      .then((response) => {
        this.model = response.data;
      });
  },
  computed: {
    destinations: function () {
      return this.model.destinations.map(({ name }) => {
        return name;
      });
    },
    getReturnDate: function () {
      var nights = this.form.nights;
      var currentDate = dayjs(this.form.departureDate);
      var returnDate = currentDate.add(nights, "day");
      form.returnDate = returnDate.toDate();
      return returnDate.format("D MMM YYYY");
    },
    numberOfTravellers: function () {
      var numOftravellers = [];

      for (var i = 1; i < 8; i++) {
        numOftravellers.push(i);
      }

      return numOftravellers;
    },
    numberOfNights: function () {
      var nights = [];

      for (var i = 1; i < 30; i++) {
        nights.push(i);
      }

      return nights;
    },
  },
  methods: {
    hideKeyboardOnMobile() {
      if (document.body.classList.contains("has-overlay")) {
        return "readonly";
      }
      return false;
    },
    submitButtonUrl: function (url, provider) {
      if (provider == "flyshannon") {
        if (this.form.destination == "") {
          this.inValidForm = true;
          return;
        } else {
          this.inValidForm = false;
        }
        var departureDate = dayjs(this.form.departureDate);
        url +=
          "=all/" +
          this.form.destination +
          "/" +
          departureDate.format("YYYY-MM-DD") +
          "/" +
          this.form.nights +
          "/" +
          this.form.travellerAdult +
          "::" +
          this.form.travellerChild +
          "//SNN/0";

        window.open(url, "_blank");
      }
    },
  },
};
</script>
