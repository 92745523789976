<template>
  <section>
    <div
      v-html="stickyBookingBarCarHireIframeObjhtml"
      id="stickyBookingBarCarHireIframe"
      class="carHireIframeSectionSolo"
    ></div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "StickyBookingBarCarHireIframeForm",
  props: ["selectedLanguage", "componentData", "pageInfo"],
  data() {
    return {
      webpage: "",
      stickyBookingBarCarHireIframeObjhtml: "",
    };
  },
  computed: {},
  mounted() {
    this.loadStickyBookingBarCarHireIframeObj();
  },
  methods: {
    loadStickyBookingBarCarHireIframeObj() {
      const apiUrl =
        process.env.VUE_APP_REMOTEBASEURL +
        `/api/CarHireIframeApi/CarHireIframeObj?` +
        `&iataCode=${process.env.VUE_APP_IATACODE}`;
      axios
        .get(apiUrl)
        .then((response) => {
          this.stickyBookingBarCarHireIframeObjhtml = response.data.html;
          if (response.data.script != "") {
            let divScripts = document.getElementById(
              "stickyBookingBarCarHireIframe"
            );
            let newScript = document.createElement("script");
            newScript.src = response.data.script;
            divScripts.appendChild(newScript);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss">
.carHireIframeSectionSolo {
  transform: translateY(-35px);

  iframe {
    width: 100%;
    max-width: 100%;
    height: 340px;

    @include breakpoint($desktop) {
      height: 325px;
    }
  }
}

@media (min-width: 1200px) {
  .carHireIframeSectionSolo iframe {
    height: 440px;
  }
}
</style>
