<template>
  <div class="mixed-alert">
    <div class="mixed-alert__modal" v-if="alertModalOpen">
      <div class="mixed-alert__modal-inner">
        <button class="mixed-alert__modal-close" @click="toggleAlertModal">
          <SvgIcon name="close" />
        </button>
        <MixedAlertModalAlerts :alerts="redAlerts" color="red" />
        <MixedAlertModalAlerts :alerts="amberAlerts" color="amber" />
      </div>
    </div>
    <button class="mixed-alert__launcher" @click="toggleAlertModal">
      <SvgIcon name="exclamation-circle" />
    </button>
  </div>
</template>

<script>
import SvgIcon from "@/components/Icon";
import MixedAlertModalAlerts from "./MixedAlertModalAlerts.vue";

export default {
  props: {
    amberAlerts: {
      type: Array,
      required: true,
    },
    redAlerts: {
      type: Array,
      required: true,
    },
  },
  components: {
    SvgIcon,
    MixedAlertModalAlerts,
  },
  methods: {
    toggleBodyOverflow() {
      const body = document.querySelector("body");
      this.alertModalOpen
        ? body.classList.add("overflow-hidden")
        : body.classList.remove("overflow-hidden");
    },
    toggleAlertModal() {
      this.alertModalOpen = !this.alertModalOpen;
      this.toggleBodyOverflow();
    },
  },
  data() {
    return {
      alertModalOpen: false,
    };
  },
};
</script>

<style lang="scss">
.mixed-alert {
  margin-right: 2rem;

  @include breakpoint($desktop-sml) {
    display: none;
  }
}

.mixed-alert__modal {
  position: fixed;
  z-index: 10000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: scroll;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background-color: rga(0, 0, 0, 0.8);
    display: block;
  }

  &-inner {
    width: 95%;
    max-width: 60rem;
    position: relative;
  }

  &-close {
    position: absolute;
    top: 2rem;
    right: 2rem;
    color: #ffffff;

    .close {
      display: none;
    }
  }
}

.mixed-alert__launcher {
  transform: translateX(6px);

  .exclamation-circle {
    width: 32px;
    height: 32px;
    color: #ef4444;
  }
}
</style>
