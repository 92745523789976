<template>
  <div
    class="hero-slider__slide"
    :style="{
      backgroundImage:
        'url(' + heroData.fields.image.fields.umbracoFile.src
          ? heroData.fields.image.fields.umbracoFile.src
          : '' + ')',
    }"
  >
    <CarForm :heroData="heroData" />
    <div :class="''"></div>
  </div>
</template>

<script>
import CarForm from "@/components/InnerForms/Car.vue";

export default {
  name: "HeroCar",
  props: ["heroData"],
  components: {
    CarForm,
  },
};
</script>

<style lang="scss"></style>
