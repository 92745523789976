<template>
  <div
    class="hero-slider__slide"
    :style="{
      backgroundImage:
        'url(' + heroData.fields.securityHeroImage.fields.umbracoFile.src + ')',
    }"
  >
    <SecurityForm :heroData="heroData" />
    <div
      :class="heroData.fields.securityApplyGradient ? 'hero__gradient' : ''"
    ></div>
  </div>
</template>

<script>
import SecurityForm from "@/components/InnerForms/Security.vue";

export default {
  name: "HeroSecurity",
  props: ["heroData"],
  components: {
    SecurityForm,
  },
};
</script>

<style lang="scss"></style>
