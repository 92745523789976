<template>
  <div>
    <section class="panel-padding lighter-bg media-post">
      <div class="inner">
        <div class="media-post__content">
          <div class="media-post__head cf">
            <p class="media-post__date" itemprop="datePublished">
              <i class="icon icon-calendar" aria-hidden="true"></i>
              {{ convertDateFormat(componentData.fields.articleDate) }}
            </p>
          </div>
          <h1 itemprop="headline">
            {{ componentData.fields.headerTitle }}
          </h1>
          <img
            v-if="componentData.fields.headerImage"
            :style="{
              backgroundImage:
                'url(' + componentData.fields.headerImage.fields.umbracoFile.src
                  ? componentData.fields.headerImage.fields.umbracoFile.src
                  : '' + ')',
            }"
          />
          <div v-html="componentData.fields.bodyText"></div>
          <a
            v-if="componentData.fields.articleELink"
            class="btn btn--primary"
            :target="componentData.fields.articleELink.target ? '_blank' : null"
            :href="componentData.fields.articleELink.url"
            >{{ componentData.fields.articleELink.name }}</a
          >
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "BlogArticle",
  props: ["componentData"],
  data() {
    return {
      date: "",
      languages: [],
    };
  },
  methods: {
    convertDateFormat(dateTimeString) {
      let datePart = dateTimeString.substring(0, 10);
      let [year, month, day] = datePart.split("-");
      let formattedDate = `${day}/${month}/${year}`;

      return formattedDate;
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-padding {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
  @include breakpoint($desktop-sml) {
    padding-top: 7rem;
    padding-bottom: 4rem;
  }
}

.lighter-bg {
  background: #f4f7ff;
}

.media-post {
  &--banner {
    padding-top: 0;

    .media-post__content {
      position: relative;
      margin-top: -2rem;

      @include breakpoint($tablet) {
        margin-top: -3rem;
      }

      @include breakpoint($desktop) {
        margin-top: -4rem;
      }
    }
  }

  &__content {
    position: relative;
    z-index: 2;
    max-width: 95rem;
    margin: 0 auto;
    border: 1px solid $keyline;
    background: $white;
    padding: 4rem 2.5rem;
    border-radius: 12px;

    @include breakpoint($tablet) {
      padding: 4rem 9rem;
    }

    h2 {
      @include breakpoint($tablet) {
        margin-bottom: 3rem;
      }

      @include breakpoint($desktop-sml) {
        margin-bottom: 6rem;
      }
    }

    p img {
      border-radius: 12px;
    }

    figure {
      margin: 4rem auto;

      img {
        border-radius: 12px;
      }

      figcaption {
        color: #9099b3;
        font-size: 1.5rem;
        margin-top: 1.5rem;
        line-height: 1.4;
      }
    }

    p a {
      color: #00a8e1;
    }

    ul {
      margin: 3rem 0;

      li {
        position: relative;
        margin-bottom: 2rem;
        padding-left: 1.5rem;
        line-height: 1.5;
        font-size: 1.6rem;

        @include breakpoint($desktop-sml) {
          font-size: 1.8rem;
        }

        &::before {
          content: "";
          background: #003e7a;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          display: block;
          position: absolute;
          top: 10px;
          left: 0;
        }
      }
    }
  }

  &__head {
    margin: 0 0 4rem 0;
  }

  &__tag {
    text-align: center;
    padding: 1rem 2rem;
    background: #e4ebfa;
    border-radius: 4px;
    margin-bottom: 2rem;

    @include breakpoint($mobile-land) {
      display: inline-block;
      float: right;
      margin: 0;
    }
  }

  &__date {
    color: #09599c;
    font-size: 1.5rem;

    @include breakpoint($mobile-land) {
      display: inline-block;
      float: left;
      margin: 15px 0 0;
    }

    i {
      margin-top: -2px;
    }
  }

  &__author {
    div {
      border-radius: 50%;
      float: left;
      width: 4rem;
      height: 4rem;

      background: {
        size: cover;
        repeat: no-repeat;
        position: 50% 50%;
      }
    }

    p {
      font-size: 1.5rem;
      margin-left: 1rem;
      float: left;
      margin-bottom: 0;

      span {
        display: block;
        color: #09599c;
      }
    }

    margin-bottom: 3rem;
  }
}

.media-post-image {
  position: relative;
  height: 33rem;

  background: {
    size: cover;
    repeat: no-repeat;
    position: 50% 50%;
  }

  @include breakpoint($tablet) {
    height: 38rem;
  }

  @include breakpoint($desktop) {
    height: 42rem;
  }

  &__btn {
    width: 9rem;
    height: 9rem;
    position: absolute;
    z-index: 2;
    top: calc(50% - 1rem);
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    @include breakpoint($tablet) {
      top: calc(50% - 4rem);
    }

    @include breakpoint($desktop) {
      top: calc(50% - 6rem);
    }

    i {
      display: block;
    }
  }

  &__gradient {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.4);
  }
}
</style>
