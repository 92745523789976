<template>
  <section class="currency-widget">
    <div class="currency-form">
      <div class="currency-form__currency">
        <label for="">{{ this.labels.value.currencyFieldOne }}</label>
        <Select2
          id="currency-change"
          :options="currencyNames"
          :settings="{
            placeholder: currencyNames[0],
          }"
          :value="startExRate"
          v-model="chosenCurrency"
          @select="getConversion()"
        >
        </Select2>
      </div>
      <div class="currency-form__input_one">
        <label for=""
          >{{ this.labels.value.currencyFieldTwo }}
          {{ localeConfig.currency }}</label
        >
        <input
          inputmode="numeric"
          class="selectbox"
          v-model="currencyAmount"
          placeholder="0"
          @input="updateTotalOnChangeForBookingBar('currencyAmount')"
          @click="clearInputForBookingBar"
        />
      </div>
      <div class="currency-form__switcher">
        <label for=""></label>
        <div class="currency-form__switch">
          <button @click="swapCurrenciesForBookingBar">
            <SvgIcon name="arrows-left-right" />
          </button>
        </div>
      </div>
      <div class="currency-form__input_two">
        <label for=""
          >{{ this.labels.value.currencyFieldThree }}
          {{ chosenCurrency }}</label
        >
        <input
          inputmode="numeric"
          class="selectbox"
          v-model="currencyEstimate"
          placeholder="0"
          @input="updateTotalOnChangeForBookingBar('currencyEstimate')"
          @click="clearInputForBookingBar"
        />
      </div>
      <div class="currency-form__purchase">
        <div></div>
        <a
          :href="
            currencyPinnedBookingBarSettings.fields
              .currencyExchangeAffiliateLink
          "
          class="btn btn--primary"
          tabindex="0"
        >
          Get Currency
        </a>
      </div>
    </div>
    <div class="currency-output">
      <label>Currency Exchange Rate</label>
      <span class="currency-output__rate">
        1 {{ localeConfig.currency }} = {{ staticConversionRate }}
        {{ chosenCurrency }}
      </span>
    </div>
  </section>
</template>

<script>
import Select2 from "vue3-select2-component";
import parkingForm from "../../mixins/parkingForm";
import axios from "axios";
import SvgIcon from "@/components/Icon";

const LOCALE_CONFIG_MAPPING = {
  "en-US": {
    currency: "$",
  },
  "en-EU": {
    currency: "€",
  },
  "en-GB": {
    currency: "£",
  },
  // add more mappings as needed
};

export default {
  name: "StickyBookingBarCurrencyForm",
  props: {
    currencyPinnedBookingBarSettings: Object,
  },
  mixins: [parkingForm],
  computed: {
    localeConfig() {
      // Return a default configuration if locale not found.
      return (
        LOCALE_CONFIG_MAPPING[this.localAreaCode] ||
        LOCALE_CONFIG_MAPPING["en-US"]
      );
    },
  },
  data() {
    return {
      chosenCurrency: "",
      currencyAmount: 0,
      currencyEstimate: 0,
      exchangeRate: 0,
      resObj: {},
      currencyNames: [],
      hasCurrencyBeenSwapped: false,
      localAreaCode: this.currencyPinnedBookingBarSettings.fields.globalLocale,
      staticConversionRate: 0,
      startExRate: 0,
    };
  },
  methods: {
    getStaticConversion() {
      var devideByER = 1 / this.exchangeRate;
      var total = 1 * devideByER;
      this.staticConversionRate = Number(total.toFixed(2));
    },
    getConversion() {
      function isFirstDigitOneOrGreater(number) {
        const numberString = number.toString();
        const firstCharacter = numberString.charAt(0);
        const firstDigit = parseInt(firstCharacter);
        return firstDigit >= 1;
      }

      function countDecimalPlacesInBookingBar(number) {
        const numberStr = number.toString();
        const decimalIndex = numberStr.indexOf(".");

        if (decimalIndex === -1) return 0;

        const decimalPart = numberStr.slice(decimalIndex + 1);

        let lastNonZeroIndex = -1;
        for (let i = decimalPart.length - 1; i >= 0; i--) {
          if (decimalPart[i] !== "0") {
            lastNonZeroIndex = i;
            break;
          }
        }
        if (lastNonZeroIndex === -1) return 0;

        return lastNonZeroIndex + 1;
      }

      for (let i = 0; i < this.currencyNames.length; i++) {
        if (this.chosenCurrency === this.currencyNames[i]) {
          this.exchangeRate = this.resObj[this.currencyNames[i]];

          if (isFirstDigitOneOrGreater(this.exchangeRate)) {
            this.exchangeRate = Number(this.exchangeRate.toFixed(2));
          } else if (countDecimalPlacesInBookingBar(this.exchangeRate) < 4) {
            this.exchangeRate = Number(this.exchangeRate.toFixed(3));
          }
          Math.ceil(this.exchangeRate);
          this.updateTotalOnChangeForBookingBar();
          this.getStaticConversion();
        }
      }
    },
    clearInputForBookingBar() {
      if (this.currencyAmount == 0) {
        return (this.currencyAmount = "");
      }
    },
    updateTotalOnChangeForBookingBar(whichBox) {
      if (whichBox == "currencyAmount") {
        var divideByER = 1 / this.exchangeRate;
        var total = this.currencyAmount * divideByER;
        this.currencyEstimate = Number(total.toFixed(2));
      } else {
        var totalE = this.currencyEstimate * this.exchangeRate;
        this.currencyAmount = Number(totalE.toFixed(2));
      }
    },
    swapCurrenciesForBookingBar() {
      if (this.hasCurrencyBeenSwapped == false) {
        var swapBackValue = this.currencyAmount;
        this.currencyAmount = this.currencyEstimate;
        this.hasCurrencyBeenSwapped = true;
        this.updateTotalOnChangeForBookingBar("currencyAmount");
      } else {
        var swapValue = this.currencyAmount;
        this.currencyEstimate = this.currencyAmount;
        this.hasCurrencyBeenSwapped = false;
        this.updateTotalOnChangeForBookingBar("currencyEstimate");
      }
    },
  },
  mounted() {
    const requestString =
      process.env.VUE_APP_REMOTEBASEURL +
      "/api/CurrencyApi/RetrieveCurrencyEstimate?iataCode=" +
      process.env.VUE_APP_IATACODE;
    axios
      .get(requestString)
      .then((response) => {
        if (response.data) {
          this.resObj = response.data.obj;
          this.currencyNames = response.data.currencyNames;
          this.exchangeRate = this.resObj[this.currencyNames[0]];
          this.chosenCurrency = this.currencyNames[0];
          this.getStaticConversion();
        }
      })
      .catch((error) => {
        console.log("No currency data received.");
        console.log(error);
      });
  },
  components: {
    SvgIcon,
    Select2,
  },
};
</script>

<style lang="scss">
.currency-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 0;

  @include breakpoint($desktop) {
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    padding: 0;
  }

  > div {
    display: grid;
    grid-template-rows: subgrid;
    grid-row: span 2;
  }

  .currency-form__input_one,
  .currency-form__input_two {
    label {
      margin: 0;
      padding: 1rem 0;
      background-color: #4c4945;
      color: #ffffff;

      @include breakpoint($desktop) {
        background-color: transparent;
      }
    }
  }

  .currency-form__switcher {
    label {
      display: none;

      @include breakpoint($desktop) {
        display: block;
      }
    }
  }

  input {
    width: 100% !important;
    padding: 0 1rem;
    border-radius: 2px;
    height: 50px;
    font-size: 2rem;
    font-family: $font-base;
  }

  &__currency {
    grid-area: 1 / 1 / 2 / 4;
  }

  &__input_one {
    grid-area: 1 / 4 / 2 / 6;
  }

  &__switcher {
    grid-area: 1 / 6 / 2 / 7;
    padding: 1rem 0;

    @include breakpoint($desktop) {
      padding: 0;
    }
  }

  &__switch {
    display: flex;
    align-items: center;
    justify-content: center;

    .arrows-left-right {
      width: 30px;
      height: 30px;

      @include breakpoint($desktop) {
        color: #ffffff;
      }
    }
  }

  &__input_two {
    grid-area: 1 / 7 / 2 / 9;
  }

  &__purchase {
    grid-area: 1 / 9 / 2 / 11;
  }
}

.currency-output {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  gap: 1rem;

  label {
    margin-top: 0 !important;
  }

  &__rate {
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
    font-weight: 600;

    @include breakpoint($desktop) {
      color: #ffffff;
    }
  }
}

.selectbox {
  height: 148px;
  text-align: left;
  border: 1px solid #e0e4f0;
  display: inline-block;
  background-color: white;
  width: 100% !important;
  color: unset !important;

  @include breakpoint($desktop) {
    width: 100% !important;
  }

  .select2 {
    width: 100% !important;
  }

  .exRate {
    color: unset;
  }
}
</style>
