<template>
  <section class="taxi-widget">
    <div class="form form--inline">
      <fieldset>
        <div class="grid">
          <div class="grid__third">
            <label for="pickUpLocation">{{
              this.labels.value.labelBookATaxi_PickUpLocation
            }}</label>
            <Select2
              :options="airports"
              :settings="{
                placeholder: 'Please select',
              }"
              v-model="pickUpLocation"
              class=""
            />
          </div>

          <div class="grid__sixth">
            <label for="pickUpDate">{{
              this.labels.value.labelBookATaxi_PickUpDate
            }}</label>
            <DatePicker
              v-model="pickUpDate"
              :min-date="new Date()"
              :masks="{ input: this.displayFormat }"
              class="datepicker"
              :popover="{ visibility: 'focus' }"
              is-required
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  :value="inputValue || this.labels.value.labelDate"
                  v-on="inputEvents"
                  :readonly="this.hideKeyboardOnMobile()"
                />
              </template>
            </DatePicker>
          </div>

          <div class="grid__sixth">
            <label for="pickUpHour"
              ><span class="hiddenFromView">Pick-up</span>
              {{ this.labels.value.labelBookATaxi_PickUpTime }}
              <span class="hiddenFromView">hours</span></label
            >
            <div class="date-time long-label">
              <div class="col-time-hours">
                <Select2
                  :options="[
                    '00',
                    '01',
                    '02',
                    '03',
                    '04',
                    '05',
                    '06',
                    '07',
                    '08',
                    '09',
                    '10',
                    '11',
                    '12',
                    '13',
                    '14',
                    '15',
                    '16',
                    '17',
                    '18',
                    '19',
                    '20',
                    '21',
                    '22',
                    '23',
                  ]"
                  v-model="pickUpHour"
                />
              </div>
              <div class="col-time-mins">
                <label for="pickUpMinute"
                  ><span class="hiddenFromView"
                    >Pick-up time minutes</span
                  ></label
                >
                <Select2
                  :options="[
                    '00',
                    '05',
                    '10',
                    '15',
                    '20',
                    '25',
                    '30',
                    '35',
                    '40',
                    '45',
                    '50',
                    '55',
                  ]"
                  v-model="pickUpMinute"
                />
              </div>
            </div>
          </div>

          <div class="grid__sixth">
            <label for="dropOffDate">{{
              this.labels.value.labelBookATaxi_DropOffDate
            }}</label>
            <DatePicker
              v-model="dropOffDate"
              :min-date="new Date()"
              :masks="{ input: this.displayFormat }"
              class="datepicker"
              :popover="{ visibility: 'focus' }"
              is-required
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  :value="inputValue || this.labels.value.labelDate"
                  v-on="inputEvents"
                  :readonly="this.hideKeyboardOnMobile()"
                />
              </template>
            </DatePicker>
          </div>
          <div class="grid__sixth">
            <label for="dropOffHour"
              ><span class="hiddenFromView">Drop-off</span>
              {{ this.labels.value.labelBookATaxi_DropOffTime
              }}<span class="hiddenFromView">hours</span></label
            >
            <div class="date-time long-label">
              <div class="col-time-hours">
                <Select2
                  :options="[
                    '00',
                    '01',
                    '02',
                    '03',
                    '04',
                    '05',
                    '06',
                    '07',
                    '08',
                    '09',
                    '10',
                    '11',
                    '12',
                    '13',
                    '14',
                    '15',
                    '16',
                    '17',
                    '18',
                    '19',
                    '20',
                    '21',
                    '22',
                    '23',
                  ]"
                  v-model="dropOffHour"
                />
              </div>
              <div class="col-time-mins">
                <label for="dropOffMinute"
                  ><span class="hiddenFromView"
                    >Drop-off time minutes</span
                  ></label
                >
                <Select2
                  :options="[
                    '00',
                    '05',
                    '10',
                    '15',
                    '20',
                    '25',
                    '30',
                    '35',
                    '40',
                    '45',
                    '50',
                    '55',
                  ]"
                  v-model="dropOffMinute"
                />
              </div>
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset>
        <div class="grid">
          <div class="grid__half">
            <div class="grid__quarter">
              <label for="adults">{{
                this.labels.value.labelBookATaxi_Adults
              }}</label>
              <Select2 :options="['1', '2', '3', '4']" v-model="adults" />
            </div>
            <div class="grid__quarter">
              <label for="children">{{
                this.labels.value.labelBookATaxi_Children
              }}</label>
              <Select2
                :options="['0', '1', '2', '3', '4']"
                v-model="children"
              />
            </div>
            <div class="grid__quarter">
              <label for="infants">{{
                this.labels.value.labelBookATaxi_Infants
              }}</label>
              <Select2 :options="['0', '1', '2', '3', '4']" v-model="infants" />
            </div>
          </div>
          <div class="grid__half">
            <button
              @click="getURLPOST()"
              type="submit"
              class="btn btn--primary btn-booktaxi"
              tabindex="0"
            >
              Book Taxi
            </button>
          </div>
        </div>
      </fieldset>
    </div>
  </section>
</template>

<script>
import Select2 from "vue3-select2-component";
import { DatePicker } from "v-calendar";
import axios from "axios";
import dayjs from "dayjs";
export default {
  name: "StickyBookingBarBookTaxiForm",
  props: ["taxiData", "componentData", "selectedLanguage"],
  inject: ["publicSiteSettings", "labels"],
  data() {
    return {
      pickUpLocation: null,
      pickUpDate: null,
      pickUpHour: "00",
      pickUpMinute: "00",
      dropOffDate: null,
      dropOffHour: "00",
      dropOffMinute: "00",
      airports: [],
      taxiURL: "",
      displayFormat:
        this.publicSiteSettings.value.fields.globalLocale === "en-US"
          ? "DD-MM-YYYY"
          : "DD-MM-YYYY",
      timeFormat:
        this.publicSiteSettings.value.fields.globalLocale === "en-US"
          ? "H:mm:ss"
          : "H:mm:ss",
      displayFormatReverse: "YYYY-MM-DD",
      adults: "1",
      children: "0",
      infants: "0",
    };
  },
  computed: {},
  components: {
    Select2,
    DatePicker,
  },
  mounted() {
    this.loadLocations();
  },
  watch: {
    selectedLanguage: "loadLocations",
  },
  methods: {
    hideKeyboardOnMobile() {
      if (document.body.classList.contains("has-overlay")) {
        return "readonly";
      }
      return false;
    },
    loadLocations() {
      const apiUrl =
        process.env.VUE_APP_REMOTEBASEURL +
        `/api/AirportsApi/GetAirportsLite?url=/airports/&iataCode=${process.env.VUE_APP_IATACODE}&cultureCode=${this.selectedLanguage}`;

      axios
        .get(apiUrl)
        .then((response) => {
          this.airports = response.data.airports;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formatDateTime(incomingDate, returnType) {
      let returnFormat = this.displayFormat + " " + this.timeFormat;
      switch (returnType) {
        case "date":
          returnFormat = this.displayFormat;
          break;
        case "time":
          returnFormat = this.timeFormat;
          break;
        case "dateReverse":
          returnFormat = this.displayFormatReverse;
          break;
      }
      return dayjs(incomingDate).format(returnFormat);
    },
    getURLPOST() {
      const pickUpTimeString =
        this.pickUpHour + ":" + this.pickUpMinute + ":00";
      const dropOffTimeString =
        this.dropOffHour + ":" + this.dropOffMinute + ":00";
      const apiUrl =
        process.env.VUE_APP_REMOTEBASEURL + `/api/BookTaxiApi/GetTaxi`;
      axios({
        method: "post",
        url: apiUrl,
        headers: {},
        data: {
          pickUpLocation: this.pickUpLocation,
          dropOffLocation: this.pickUpLocation,
          pickUpDate: this.formatDateTime(this.pickUpDate, "date"),
          pickUpTime: pickUpTimeString,
          returnDate: this.formatDateTime(this.dropOffDate, "date"),
          returnTime: dropOffTimeString,
          adults: this.adults,
          children: this.children,
          infants: this.infants,
          iataCode: process.env.VUE_APP_IATACODE,
          cultureCode: this.selectedLanguage,
        },
      })
        .then((response) => {
          this.taxiURL = response.data;
          window.open(this.taxiURL);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
